.card-ipd-head {
  display: flex;
  justify-content: space-between;
}

.ipd-body {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ipd-body-left {
  display: flex;
  flex-direction: column;
}

.ipd-body-right {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ipd-buttons {
  display: flex;
  justify-content: space-between;
}

.inline {
  display: flex;
  justify-content: space-between;
}
