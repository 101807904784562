.main {
  padding-top: 70px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  position: relative; 
}

.sticky{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: sticky;
  top: 5px;
  left:5px;
  padding-left: 5px;
  z-index: 2;
}

.top_header {
  display: flex;
  justify-content: space-between;
}

.cve {
  display: flex;
  justify-content: space-between;
  margin-right: 25px;
}

.data {
  font-size: 20px !important;
}

.line-before {
  border-left: 1px solid #000 !important;
  padding-left: 10px !important; /* Ensure the padding is applied correctly */
}

.heading {
  font-size: 20px;
  font-weight: bold;
}

@media print {
  @import url("https://fonts.cdnfonts.com/css/calibri-light");
  body,
  html {
    font-family: "Calibri Light", sans-serif;
    color: black;
    display: block;
  }

  .main {
    padding-top: 180px;
    padding-left: 50px;
  }

  hr {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .top_header {
    display: flex;
    justify-content: space-between;
  }

  .cve {
    display: flex;
    justify-content: space-between;
    margin-right: 25px;
  }

  .data {
    font-size: 20px !important;
  }

  .line-before {
    border-left: 1px solid #000 !important;
    padding-left: 10px !important; /* Ensure the padding is applied correctly */
  }

  .heading {
    font-size: 20px;
    font-weight: bold;
  }
}
