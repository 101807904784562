/* -----------------------------

[Table of Contents]
    * Variables
        + Fonts
        + Responsive
    * Main Styles
        + Preloader
        + BS Color Reset
        + Reboot
        + Shortcode
        + Miscellaneous
        + Internet Status
        + Animation
        + Register
        + Header
        + Sidenav
        + Footer
    * Element CSS
        + Btn
        + Badge
        + Breadcrumb
        + Accordion
        + Alerts
        + Divider
        + Coming Soon
        + Card
        + Carousel
        + Countdown
        + Counterup
        + Form
        + Hero Block
        + Image Gallery
        + List Group
        + Loader
        + Modal
        + Notification
        + Pagination
        + Partner
        + Price Table
        + Progress
        + Rating
        + Scrollspy
        + Table
        + Testimonial
        + Timeline
        + Toast
    * Page CSS
        + Blog
        + Cart
        + Language
        + Product
        + Service
        + Team
        + User Profile
        + Demo
    * RTL & Dark CSS
        + RTL
        + Dark

# [font-family]
"Plus Jakarta Sans", sans-serif;
----------------------------- */
@import url("css/bootstrap.min.css");
@import url("css/bootstrap-icons.min.css");
@import url("css/tiny-slider.css");
@import url("css/venobox.min.css");
@import url("css/rangeslider.css");
@import url("css/vanilla-dataTables.min.css");
@import url("css/apexcharts.css");
@import url("css/fonts/fonts.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css");
/* :: Preloader */
#preloader {
  background-color: var(--primary-100);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* :: Bootstrap Color Reset */
a,
.btn-link,
.link-primary {
  color: var(--primary-600);
}

kbd {
  background-color: var(--dark);
}

.form-control::-webkit-input-placeholder {
  color: var(--text);
}

caption,
.blockquote-footer,
.figure-caption,
.form-control::-webkit-input-placeholder,
.form-control::-moz-placeholder,
.form-control::-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-control::placeholder,
.btn-link:disabled,
.btn-link.disabled,
.dropdown-item.disabled,
.dropdown-item:disabled,
.dropdown-header,
.nav-link.disabled,
.nav-tabs .nav-link.disabled,
.form-text,
.link-secondary,
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: var(--text);
}

.table,
.img-thumbnail {
  border-color: var(--primary-200);
}

.table-secondary,
.table-primary,
.table-success,
.table-info,
.table-warning,
.table-danger,
.table-light,
.form-control-plaintext,
.btn,
.btn:hover,
.dropdown-item,
.dropdown-item-text,
.list-group-item-action,
.popover-body {
  color: var(--primary-950);
}

.form-control,
.form-select {
  color: var(--primary-950);
  border-color: var(--primary-200);
}

.form-select:focus::-ms-value {
  color: var(--primary-950);
  background-color: var(--white);
}

.form-select:disabled {
  color: var(--text);
  background-color: var(--primary-100);
}

.form-check-input {
  background-color: var(--white);
  border: 1px solid var(--primary-200);
}

.form-check-input:checked,
.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--primary-500);
  border-color: var(--primary-500);
}

.form-file-text {
  color: var(--primary-950);
  background-color: var(--white);
}

.form-file-button {
  color: var(--primary-950);
  background-color: var(--primary-100);
}

.form-range::-webkit-slider-thumb,
.form-range::-moz-range-thumb,
.form-range::-ms-thumb {
  background-color: var(--primary-950);
}

.input-group-text {
  color: var(--primary-950);
  background-color: #cff2ff;
  border-color: #cff2ff;
}

.valid-feedback {
  color: var(--success-500);
}

.valid-tooltip {
  color: var(--white);
  background-color: var(--success-700);
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-select:valid,
.form-select.is-valid,
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus,
.was-validated .form-check-input:valid,
.form-check-input.is-valid,
.was-validated .form-file-input:valid ~ .form-file-label,
.form-file-input.is-valid ~ .form-file-label,
.was-validated .form-file-input:valid:focus ~ .form-file-label,
.form-file-input.is-valid:focus ~ .form-file-label {
  border-color: var(--success-500);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--success-500);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--success-500);
}

.invalid-feedback {
  color: var(--danger-500);
}

.invalid-tooltip {
  color: var(--white);
  background-color: var(--danger-700);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .form-select:invalid,
.form-select.is-invalid,
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus,
.was-validated .form-check-input:invalid,
.form-check-input.is-invalid,
.was-validated .form-file-input:invalid ~ .form-file-label,
.form-file-input.is-invalid ~ .form-file-label,
.was-validated .form-file-input:invalid:focus ~ .form-file-label,
.form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: var(--danger-500);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--danger-500);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--danger-500);
}

.btn-primary,
.btn-primary:disabled,
.btn-primary.disabled {
  color: var(--white);
  background-color: var(--primary-500);
  border-color: var(--primary-500);
}

.btn-primary:hover,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: var(--white);
  background-color: var(--primary-700);
  border-color: var(--primary-700);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: var(--white);
  background-color: var(--primary-700);
  border-color: var(--primary-700);
}

.btn-secondary {
  color: var(--primary-950);
  background-color: var(--secondary-500);
  border-color: var(--secondary-500);
}

.btn-secondary:hover,
.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--primary-950);
  background-color: var(--secondary-700);
  border-color: var(--secondary-700);
}

.btn-success,
.btn-success:disabled,
.btn-success.disabled {
  color: var(--white);
  background-color: var(--success-500);
  border-color: var(--success-500);
}

.btn-success:hover,
.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: var(--white);
  background-color: var(--success-700);
  border-color: var(--success-700);
}

.btn-info,
.btn-info:disabled,
.btn-info.disabled {
  color: var(--white);
  background-color: var(--info-500);
  border-color: var(--info-500);
}

.btn-info:hover,
.btn-check:focus + .btn-info,
.btn-info:focus,
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: var(--white);
  background-color: var(--info-700);
  border-color: var(--info-700);
}

.btn-warning,
.btn-warning:disabled,
.btn-warning.disabled {
  color: var(--primary-950);
  background-color: var(--warning-500);
  border-color: var(--warning-500);
}

.btn-warning:hover,
.btn-check:focus + .btn-warning,
.btn-warning:focus,
.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: var(--primary-950);
  background-color: var(--warning-700);
  border-color: var(--warning-700);
}

.btn-danger,
.btn-danger:disabled,
.btn-danger.disabled {
  color: var(--white);
  background-color: var(--danger-500);
  border-color: var(--danger-500);
}

.btn-danger:hover,
.btn-check:focus + .btn-danger,
.btn-danger:focus,
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: var(--white);
  background-color: var(--danger-700);
  border-color: var(--danger-700);
}

.btn-light,
.btn-light:disabled,
.btn-light.disabled {
  color: var(--primary-950);
  background-color: var(--primary-50);
  border-color: var(--primary-50);
}

.btn-light:hover,
.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: var(--primary-950);
  background-color: var(--primary-100);
  border-color: var(--primary-100);
}

.btn-dark,
.btn-dark:disabled,
.btn-dark.disabled {
  color: var(--white);
  background-color: var(--primary-900);
  border-color: var(--primary-900);
}

.btn-dark:hover,
.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: var(--white);
  background-color: var(--primary-950);
  border-color: var(--primary-950);
}

.btn-outline-primary {
  color: var(--primary-500);
  border-color: var(--primary-500);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: var(--white);
  background-color: var(--primary-700);
  border-color: var(--primary-700);
}

.btn-outline-secondary {
  color: var(--secondary-500);
  border-color: var(--secondary-500);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: var(--primary-950);
  background-color: var(--secondary-700);
  border-color: var(--secondary-700);
}

.btn-outline-success {
  color: var(--success-500);
  border-color: var(--success-500);
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: var(--white);
  background-color: var(--success-700);
  border-color: var(--success-700);
}

.btn-outline-info {
  color: var(--info-500);
  border-color: var(--info-500);
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: var(--white);
  background-color: var(--info-700);
  border-color: var(--info-700);
}

.btn-outline-warning {
  color: var(--warning-500);
  border-color: var(--warning-500);
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: var(--primary-950);
  background-color: var(--warning-700);
  border-color: var(--warning-700);
}

.btn-outline-danger {
  color: var(--danger-500);
  border-color: var(--danger-500);
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: var(--white);
  background-color: var(--danger-700);
  border-color: var(--danger-700);
}

.btn-outline-light {
  color: var(--text);
  border-color: var(--primary-100);
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: var(--primary-950);
  background-color: var(--primary-100);
  border-color: var(--primary-100);
}

.btn-outline-dark {
  color: var(--primary-950);
  border-color: var(--primary-950);
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: var(--white);
  background-color: var(--primary-950);
  border-color: var(--primary-950);
}

.btn-link:hover {
  color: var(--primary-500);
}

.dropdown-menu {
  color: var(--primary-950);
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-divider {
  border-top: 1px solid var(--primary-100);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  background-color: var(--primary-100);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--white);
  background-color: var(--primary-950);
}

.nav-tabs {
  border-bottom-color: var(--primary-200);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: var(--primary-200);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--primary-950);
  background-color: var(--white);
  border-color: var(--white);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--white);
  background-color: var(--primary-950);
}

.breadcrumb {
  background-color: var(--primary-100);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--text);
  content: "/";
}

.breadcrumb-item.active {
  color: var(--text);
}

.page-link {
  color: var(--primary-950);
  background-color: var(--white);
  border-color: var(--primary-200);
}

.page-link:hover {
  color: var(--primary-500);
  background-color: var(--primary-100);
  border-color: var(--primary-200);
}

.page-item.active .page-link {
  color: var(--white);
  background-color: var(--primary-950);
  border-color: var(--primary-950);
}

.page-item.disabled .page-link {
  color: var(--text);
  background-color: var(--white);
  border-color: var(--primary-200);
}

.progress {
  background-color: var(--primary-100);
}

.progress-bar {
  color: var(--white);
  background-color: var(--primary-950);
}

.list-group-item-action:hover,
.list-group-item-action:focus,
.list-group-item-action:active {
  color: var(--primary-950);
  background-color: var(--primary-100);
}

.list-group-item {
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--text);
  background-color: var(--white);
}

.list-group-item.active {
  color: var(--white);
  background-color: var(--primary-950);
  border-color: var(--primary-950);
}

.toast {
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.toast-header {
  color: var(--text);
  background-color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.modal-header {
  border-bottom: 1px solid var(--primary-100);
}

.modal-footer {
  border-top: 1px solid var(--primary-100);
}

.link-primary:hover,
.link-primary:focus {
  color: var(--primary-700);
}

.link-secondary:hover,
.link-secondary:focus {
  color: var(--secondary-700);
}

.link-success {
  color: var(--success-500);
}

.link-success:hover,
.link-success:focus {
  color: var(--success-700);
}

.link-info {
  color: var(--info-500);
}

.link-info:hover,
.link-info:focus {
  color: var(--info-700);
}

.link-warning {
  color: var(--warning-500);
}

.link-warning:hover,
.link-warning:focus {
  color: var(--warning-700);
}

.link-danger {
  color: var(--danger-500);
}

.link-danger:hover,
.link-danger:focus {
  color: var(--danger-700);
}

.link-light {
  color: var(--primary-950);
}

.link-light:hover,
.link-light:focus {
  color: var(--primary-950);
}

.link-dark {
  color: var(--dark);
}

.link-dark:hover,
.link-dark:focus {
  color: var(--primary-950);
}

.border {
  border-color: var(--primary-100) !important;
}

.border-top {
  border-top-color: var(--primary-100) !important;
}

.border-end {
  border-right-color: var(--primary-100) !important;
}

.border-bottom {
  border-bottom-color: var(--primary-100) !important;
}

.border-start {
  border-left-color: var(--primary-100) !important;
}

.border-primary {
  border-color: var(--primary-500) !important;
}

.border-secondary {
  border-color: var(--secondary-500) !important;
}

.border-success {
  border-color: var(--success-500) !important;
}

.border-info {
  border-color: var(--info-500) !important;
}

.border-warning {
  border-color: var(--warning-500) !important;
}

.border-danger {
  border-color: var(--danger-500) !important;
}

.border-light {
  border-color: var(--primary-200) !important;
}

.border-dark {
  border-color: var(--dark) !important;
}

.text-primary {
  color: var(--primary-500) !important;
}

.text-secondary {
  color: var(--secondary-500) !important;
}

.text-success {
  color: var(--success-500) !important;
}

.text-info {
  color: var(--info-500) !important;
}

.text-warning {
  color: var(--warning-500) !important;
}

.text-danger {
  color: var(--danger-500) !important;
}

.text-light {
  color: var(--primary-100) !important;
}

.text-dark {
  color: var(--dark) !important;
}

.text-body {
  color: var(--primary-950) !important;
}

.text-muted {
  color: var(--text) !important;
}

.bg-primary {
  background-color: var(--primary-500) !important;
}

.bg-secondary {
  background-color: var(--secondary-500) !important;
}

.bg-success {
  background-color: var(--success-500) !important;
}

.bg-info {
  background-color: var(--info-500) !important;
}

.bg-warning {
  background-color: var(--warning-500) !important;
}

.bg-danger {
  background-color: var(--danger-500) !important;
}

.bg-light {
  background-color: var(--primary-200) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--primary-100);
}

.bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-950);
}

.bs-tooltip-auto[x-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--primary-950);
}

.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--primary-950);
}

.bs-tooltip-auto[x-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-950);
}

.tooltip-inner {
  padding: 0.375rem 1rem;
  background-color: var(--primary-950);
}

.tooltip.show {
  opacity: 1;
}

/* :: Reboot */
:root {
  --primary-50: #f0f5ff;
  --primary-100: #e1eaff;
  --primary-200: #c2d5ff;
  --primary-300: #9abafe;
  --primary-400: #6797fe;
  --primary-500: #0d5afd;
  --primary-600: #024ef3;
  --primary-700: #0244d4;
  --primary-800: #0139b1;
  --primary-900: #01297e;
  --primary-950: #011542;
  --secondary-500: #8696a7;
  --secondary-700: #546474;
  --success-500: #2ecc4a;
  --success-700: #25a23c;
  --danger-500: #ea4c62;
  --danger-700: #cd1830;
  --warning-500: #f1b10f;
  --warning-700: #c18d0b;
  --info-500: #1787b8;
  --info-700: #126b91;
  --white: #ffffff;
  --text: #7773a6;
  --border: #d8eaff;
  --dark: #061238;
  --dark-primary: #0C153B;
  --dark-secondary: #242644;
}

* {
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
  background-color: var(--primary-50);
  color: var(--text);
  scrollbar-width: thin;
  letter-spacing: -0.25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-950);
  font-weight: 600;
}

p {
  font-size: 14px;
  color: var(--text);
}

a,
a:hover,
a:focus {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  text-decoration: none;
  outline: 0 solid transparent;
}

ul {
  margin: 0;
}
ul li {
  list-style: none;
  text-decoration: none;
}
ul li:hover, ul li:focus {
  list-style: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

@media only screen and (min-width:1400px) {
  .container {
    max-width: 760px;
  }
}
@media only screen and (min-width:1200px) and (max-width:1399px) {
  .container {
    max-width: 740px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .container {
    max-width: 720px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .container {
    max-width: 580px;
  }
}

.custom-container {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width:1400px) {
  .custom-container {
    max-width: 500px;
  }
}
@media only screen and (min-width:1200px) and (max-width:1399px) {
  .custom-container {
    max-width: 480px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .custom-container {
    max-width: 480px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .custom-container {
    max-width: 450px;
  }
}
@media only screen and (max-width:767px) {
  .custom-container {
    max-width: 300px;
  }
}
@media only screen and (min-width:480px) and (max-width:767px) {
  .custom-container {
    max-width: 320px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .custom-container {
    max-width: 370px;
  }
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  letter-spacing: -1px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: var(--primary-100);
}

/* :: Animation CSS */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@-webkit-keyframes flashing {
  0%, 90% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
}
@keyframes flashing {
  0%, 90% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
}
@-webkit-keyframes circlebig {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes circlebig {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dotloader {
  50% {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }
}
@keyframes dotloader {
  50% {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }
}
@-webkit-keyframes circleloader2 {
  0% {
    width: 0%;
    height: 0%;
  }
  40% {
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
  100% {
    width: 240%;
    height: 240%;
    opacity: 0;
  }
}
@keyframes circleloader2 {
  0% {
    width: 0%;
    height: 0%;
  }
  40% {
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
  100% {
    width: 240%;
    height: 240%;
    opacity: 0;
  }
}
@-webkit-keyframes toast-animation {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@keyframes toast-animation {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@-webkit-keyframes typingg {
  0% {
    top: 0;
  }
  50% {
    top: -6px;
  }
  100% {
    top: 0;
  }
}
@keyframes typingg {
  0% {
    top: 0;
  }
  50% {
    top: -6px;
  }
  100% {
    top: 0;
  }
}
/* :: Shortcodes */
.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-22 {
  font-size: 22px !important;
}

.fz-24 {
  font-size: 24px !important;
}

input:required,
textarea:required,
input:invalid,
textarea:invalid {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.list-unstyled li {
  margin-top: 0.5rem;
  list-style: circle;
}
.list-unstyled li:first-child {
  margin-top: 0;
}
.list-unstyled li ul li:first-child {
  margin-top: 0.5rem;
}

.img-circle {
  border-radius: 50% !important;
}

.bi {
  display: inline-block;
}

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-overlay {
  position: relative;
  z-index: 1;
}
.bg-overlay::after {
  position: absolute;
  content: "";
  background-color: var(--dark);
  opacity: 0.75;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg-gray {
  background-color: var(--primary-100) !important;
}

.stretched-link {
  position: relative;
  z-index: 1;
  text-decoration: underline;
}
.stretched-link:hover, .stretched-link:focus {
  text-decoration: underline;
}

.border-content small {
  font-size: 11px;
  text-align: center;
  margin-bottom: 0.5rem;
}

.rounded {
  border-radius: 0.325rem !important;
}

.rounded-top {
  border-top-left-radius: 0.325rem !important;
  border-top-right-radius: 0.325rem !important;
}

.rounded-right {
  border-top-right-radius: 0.325rem !important;
  border-bottom-right-radius: 0.325rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.325rem !important;
  border-bottom-left-radius: 0.325rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.325rem !important;
  border-top-left-radius: 0.325rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.dark-mode-switching,
.rtl-mode-switching {
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-color: var(--white);
  display: none;
}
.dark-mode-switching i,
.rtl-mode-switching i {
  margin: 0 auto 0.5rem;
  color: var(--primary-950);
  font-size: 2rem;
}
.dark-mode-switching .dark-mode-text,
.dark-mode-switching .rtl-mode-text,
.rtl-mode-switching .dark-mode-text,
.rtl-mode-switching .rtl-mode-text {
  display: none;
}
.dark-mode-switching .light-mode-text,
.dark-mode-switching .ltr-mode-text,
.rtl-mode-switching .light-mode-text,
.rtl-mode-switching .ltr-mode-text {
  display: block;
}

.home-page-toast {
  position: fixed !important;
  z-index: 1000 !important;
  top: 66px;
  right: 1rem;
  max-width: 18rem;
}

/* :: Miscellaneous */
.page-content-wrapper {
  margin-top: 50px;
  margin-bottom: 62px;
}

.affan-page-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  font-size: 14px;
  background-color: transparent;
  padding: 0.5rem 0;
  color: var(--primary-950);
  border-radius: 0.4rem;
  border: 1px solid transparent;
  font-weight: 500;
}
.affan-page-item .icon-wrapper {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  margin-right: 0.5rem;
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--primary-50);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.affan-page-item .icon-wrapper i {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  font-size: 18px;
}
.affan-page-item > i {
  margin-left: auto;
}
.affan-page-item.active, .affan-page-item:hover, .affan-page-item:focus {
  background-color: var(--white);
  border-color: var(--primary-100);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 0.5rem 0.75rem;
}
.affan-page-item.active .icon-wrapper, .affan-page-item:hover .icon-wrapper, .affan-page-item:focus .icon-wrapper {
  background-color: var(--primary-100);
}

.affan-element-item {
  background-color: transparent;
  margin: 1rem 0;
}

a.affan-element-item {
  margin: 0.5rem 0;
  background-color: var(--white);
  padding: 0.625rem 0.75rem;
  color: var(--primary-950);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0.4rem;
  font-weight: 500;
  font-size: 14px;
}
a.affan-element-item i {
  margin-left: auto;
}
a.affan-element-item:hover, a.affan-element-item:focus {
  color: var(--primary-500);
}

.element-heading-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.element-heading-wrapper i {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--primary-200);
  border-radius: 0.4rem;
  text-align: center;
  color: var(--primary-950);
  margin-right: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.5rem;
          flex: 0 0 2.5rem;
  min-width: 2.5rem;
  font-size: 1.25rem;
}
.element-heading-wrapper span {
  font-size: 12px;
  color: var(--text);
  display: block;
}

.order-success-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 1rem 3rem;
}

.single-setting-panel .form-check-label {
  color: var(--text);
  cursor: pointer;
}
.single-setting-panel a {
  color: var(--text);
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single-setting-panel a .icon-wrapper {
  background-color: var(--primary-950);
  margin-right: 0.5rem;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
}
.single-setting-panel a .icon-wrapper svg,
.single-setting-panel a .icon-wrapper i {
  color: var(--white);
  line-height: 1;
}
.single-setting-panel a:hover, .single-setting-panel a:focus {
  color: var(--primary-950);
}
.single-setting-panel:last-child a {
  margin-bottom: 0;
}
.single-setting-panel .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single-setting-panel .form-check label {
  margin-left: 0.5rem;
}

#setting-popup-overlay {
  position: fixed !important;
  z-index: 100 !important;
  background-color: var(--dark);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-duration: 350ms;
          transition-duration: 350ms;
}
#setting-popup-overlay.active {
  opacity: 0.85;
  visibility: visible;
}

.setting-popup-card {
  position: fixed !important;
  height: auto;
  width: calc(100% - 1rem);
  z-index: 10000 !important;
  top: 58px;
  left: 0.5rem;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  -webkit-transform: translate(0, 20px);
          transform: translate(0, 20px);
}
.setting-popup-card #settingCardClose {
  cursor: pointer;
}
.setting-popup-card.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
          transform: none;
}

/* :: Internet Status */
.internet-connection-status {
  display: none;
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 32px;
  z-index: 99999;
  text-align: center;
  color: var(--white);
  bottom: 62px;
  left: 0;
  right: 0;
  line-height: 32px;
  font-weight: 700;
  font-size: 12px;
}

/* :: Register */
.login-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  z-index: 10;
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-x: hidden;
  background-color: var(--white);
}
.login-wrapper .brand-logo {
  max-height: 26px;
}
.login-wrapper .login-intro-img {
  max-height: 16rem;
}

.login-back-button {
  position: fixed;
  top: 2rem;
  left: 28px;
  z-index: 100;
}
.login-back-button a {
  display: block;
  line-height: 1;
}
.login-back-button a i {
  font-size: 2rem;
  line-height: 1;
}

.register-form .progress {
  width: 100%;
  height: 5px;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-bottom: 0.25rem;
}
.register-form .password-score,
.register-form .password-recommendation {
  display: none !important;
}
.register-form #password-recommendation-heading {
  font-weight: 700;
  color: var(--success-500);
  font-size: 16px;
}

#password-visibility {
  top: 50%;
  right: 1rem;
  z-index: 10;
  background-color: transparent;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#password-visibility i {
  color: var(--primary-950);
  font-size: 1.125rem;
}
#password-visibility i.bi-eye-slash {
  display: none;
}
#password-visibility.active i.bi-eye-slash {
  display: block;
}
#password-visibility.active i.bi-eye {
  display: none;
}

.login-meta-data a {
  font-size: 14px;
  color: var(--primary-950);
  font-weight: 500;
}
.login-meta-data a:hover, .login-meta-data a:focus {
  color: var(--primary-950);
}

.otp-form {
  position: relative;
  z-index: 1;
}
.otp-form select {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 90px;
          flex: 0 0 90px;
  max-width: 90px;
  width: 90px;
  text-align: left;
}

.otp-verify-form {
  position: relative;
  z-index: 1;
  text-align: center;
}
.otp-verify-form .form-control {
  font-weight: 700;
  text-align: center;
}

.otp-sec {
  color: var(--primary-950);
  font-weight: 700;
}

/* :: Header */
.header-area {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  background-color: var(--white);
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

.header-content {
  z-index: 1;
  height: 50px;
}
.header-content .back-button a {
  display: block;
  line-height: 1;
  text-align: left;
  margin-left: -0.375rem;
  color: var(--text);
}
.header-content .back-button a i {
  font-size: 2rem !important;
  line-height: 1;
}
.header-content .logo-wrapper a {
  display: block;
}
.header-content .logo-wrapper a img {
  max-height: 26px;
  width: auto;
}
.header-content .navbar--toggler {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.header-content .navbar--toggler span {
  width: 23px;
  height: 2px;
  background-color: var(--primary-950);
  margin-bottom: 5px;
}
.header-content .navbar--toggler span:nth-child(2) {
  width: 18px;
}
.header-content .navbar--toggler span:last-child {
  margin-bottom: 0;
  width: 13px;
}
.header-content .setting-trigger-btn {
  position: relative;
  z-index: 1;
  display: block;
  color: var(--primary-950);
  cursor: pointer;
  font-size: 18px;
  background: -webkit-linear-gradient(#0134d4, #28a745);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-content .setting-trigger-btn span {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: var(--primary-950);
  position: absolute;
  top: 4px;
  right: 1px;
  border-radius: 50%;
  -webkit-animation: flashing 1.2s 0s infinite;
          animation: flashing 1.2s 0s infinite;
}
.header-content .search-trigger-btn {
  color: var(--primary-950);
}
.header-content .search-trigger-btn:hover, .header-content .search-trigger-btn:focus {
  color: var(--primary-950);
}
.header-content.header-style-two .search-trigger-btn {
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--primary-50);
  border-radius: 50%;
  display: block;
  color: var(--secondary-700);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header-content.header-style-two .search-trigger-btn:hover, .header-content.header-style-two .search-trigger-btn:focus {
  color: var(--primary-950);
}
.header-content.header-style-two .navbar--toggler {
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--primary-50);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header-content.header-style-two .navbar--toggler span {
  width: 14px;
  margin-bottom: 3px;
  border-radius: 0;
  color: var(--text);
}
.header-content.header-style-two .navbar--toggler span:last-child {
  margin-bottom: 0;
}
.header-content.header-style-three .user-profile-trigger-btn {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary-50);
  border-radius: 50%;
  display: block;
  color: var(--primary-950);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header-content.header-style-three .user-profile-trigger-btn img {
  border-radius: 50%;
}
.header-content.header-style-three .user-profile-trigger-btn:hover, .header-content.header-style-three .user-profile-trigger-btn:focus {
  color: var(--primary-950);
}
.header-content.header-style-three .navbar--toggler {
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--primary-50);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header-content.header-style-three .navbar--toggler span {
  width: 14px;
  margin-bottom: 3px;
  border-radius: 0;
  color: var(--text);
}
.header-content.header-style-three .navbar--toggler span:last-child {
  margin-bottom: 0;
}
.header-content.header-style-four .user-profile-trigger-btn {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary-100);
  border-radius: 50%;
  display: block;
}
.header-content.header-style-four .user-profile-trigger-btn img {
  border-radius: 50%;
}
.header-content.header-style-four .user-profile-trigger-btn:hover, .header-content.header-style-four .user-profile-trigger-btn:focus {
  color: var(--primary-950);
}
.header-content.header-style-five .navbar--toggler {
  border: 2px solid var(--primary-100);
  padding: 5px;
  border-radius: 0.25rem;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}
.header-content.header-style-five .navbar--toggler span {
  width: 18px;
  margin-bottom: 4px;
}
.header-content.header-style-five .navbar--toggler span:nth-child(2) {
  width: 12px;
}
.header-content.header-style-five .navbar--toggler span:last-child {
  margin-bottom: 0;
}
.header-content.header-style-five .navbar--toggler:hover, .header-content.header-style-five .navbar--toggler:focus {
  border-color: var(--primary-500);
}
.header-content.header-style-six .search-trigger-btn {
  color: var(--secondary-700);
}
.header-content.header-style-six .search-trigger-btn:hover, .header-content.header-style-six .search-trigger-btn:focus {
  color: var(--primary-950);
}

.bg-success .header-content .navbar--toggler,
.bg-primary .header-content .navbar--toggler,
.bg-secondary .header-content .navbar--toggler,
.bg-dark .header-content .navbar--toggler,
.bg-danger .header-content .navbar--toggler,
.bg-info .header-content .navbar--toggler,
.bg-warning .header-content .navbar--toggler {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.2);
}
.bg-success .header-content .navbar--toggler span,
.bg-primary .header-content .navbar--toggler span,
.bg-secondary .header-content .navbar--toggler span,
.bg-dark .header-content .navbar--toggler span,
.bg-danger .header-content .navbar--toggler span,
.bg-info .header-content .navbar--toggler span,
.bg-warning .header-content .navbar--toggler span {
  background-color: var(--white);
}
.bg-success .header-content .setting-trigger-btn,
.bg-primary .header-content .setting-trigger-btn,
.bg-secondary .header-content .setting-trigger-btn,
.bg-dark .header-content .setting-trigger-btn,
.bg-danger .header-content .setting-trigger-btn,
.bg-info .header-content .setting-trigger-btn,
.bg-warning .header-content .setting-trigger-btn {
  color: var(--white);
}
.bg-success .header-content .setting-trigger-btn:hover, .bg-success .header-content .setting-trigger-btn:focus,
.bg-primary .header-content .setting-trigger-btn:hover,
.bg-primary .header-content .setting-trigger-btn:focus,
.bg-secondary .header-content .setting-trigger-btn:hover,
.bg-secondary .header-content .setting-trigger-btn:focus,
.bg-dark .header-content .setting-trigger-btn:hover,
.bg-dark .header-content .setting-trigger-btn:focus,
.bg-danger .header-content .setting-trigger-btn:hover,
.bg-danger .header-content .setting-trigger-btn:focus,
.bg-info .header-content .setting-trigger-btn:hover,
.bg-info .header-content .setting-trigger-btn:focus,
.bg-warning .header-content .setting-trigger-btn:hover,
.bg-warning .header-content .setting-trigger-btn:focus {
  color: var(--white);
}
.bg-success .header-content .search-trigger-btn,
.bg-primary .header-content .search-trigger-btn,
.bg-secondary .header-content .search-trigger-btn,
.bg-dark .header-content .search-trigger-btn,
.bg-danger .header-content .search-trigger-btn,
.bg-info .header-content .search-trigger-btn,
.bg-warning .header-content .search-trigger-btn {
  color: var(--white);
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.2);
}
.bg-success .header-content .search-trigger-btn:hover, .bg-success .header-content .search-trigger-btn:focus,
.bg-primary .header-content .search-trigger-btn:hover,
.bg-primary .header-content .search-trigger-btn:focus,
.bg-secondary .header-content .search-trigger-btn:hover,
.bg-secondary .header-content .search-trigger-btn:focus,
.bg-dark .header-content .search-trigger-btn:hover,
.bg-dark .header-content .search-trigger-btn:focus,
.bg-danger .header-content .search-trigger-btn:hover,
.bg-danger .header-content .search-trigger-btn:focus,
.bg-info .header-content .search-trigger-btn:hover,
.bg-info .header-content .search-trigger-btn:focus,
.bg-warning .header-content .search-trigger-btn:hover,
.bg-warning .header-content .search-trigger-btn:focus {
  color: var(--white);
}
.bg-success .header-content .user-profile-trigger-btn,
.bg-primary .header-content .user-profile-trigger-btn,
.bg-secondary .header-content .user-profile-trigger-btn,
.bg-dark .header-content .user-profile-trigger-btn,
.bg-danger .header-content .user-profile-trigger-btn,
.bg-info .header-content .user-profile-trigger-btn,
.bg-warning .header-content .user-profile-trigger-btn {
  color: var(--white);
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.2);
}
.bg-success .header-content .user-profile-trigger-btn:hover, .bg-success .header-content .user-profile-trigger-btn:focus,
.bg-primary .header-content .user-profile-trigger-btn:hover,
.bg-primary .header-content .user-profile-trigger-btn:focus,
.bg-secondary .header-content .user-profile-trigger-btn:hover,
.bg-secondary .header-content .user-profile-trigger-btn:focus,
.bg-dark .header-content .user-profile-trigger-btn:hover,
.bg-dark .header-content .user-profile-trigger-btn:focus,
.bg-danger .header-content .user-profile-trigger-btn:hover,
.bg-danger .header-content .user-profile-trigger-btn:focus,
.bg-info .header-content .user-profile-trigger-btn:hover,
.bg-info .header-content .user-profile-trigger-btn:focus,
.bg-warning .header-content .user-profile-trigger-btn:hover,
.bg-warning .header-content .user-profile-trigger-btn:focus {
  color: var(--white);
}

.header-demo-bg {
  background-color: var(--white);
}

/* :: Sidenav */
.offcanvas-body {
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.offcanvas-start {
  width: 300px !important;
}
.offcanvas-start .btn-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
}

.offcanvas-end {
  width: 300px !important;
}
.offcanvas-end .btn-close {
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 1000;
}

.offcanvas-top .btn-close,
.offcanvas-bottom .btn-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
}

.sidenav-wrapper .sidenav-style1 {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: -10;
  opacity: 0.1;
  width: 2rem;
  height: 3.5rem;
  border-radius: 2px 70px;
  background-color: var(--white);
}
.sidenav-wrapper .sidenav-style1::after {
  content: "";
  width: 2rem;
  height: 0.25rem;
  background-color: var(--white);
  position: absolute;
  border-radius: 0.4rem;
  bottom: 0.25rem;
  left: 2.5rem;
}

.sidenav-profile {
  position: relative;
  z-index: 1;
  background-color: var(--primary-600);
  text-align: center;
  margin-top: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.sidenav-profile .user-profile {
  position: relative;
  z-index: 1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}
.sidenav-profile .user-profile img {
  border-radius: 50%;
}
.sidenav-profile .user-info {
  position: relative;
  z-index: 1;
}
.sidenav-profile .user-info h6 {
  color: var(--white);
}
.sidenav-profile .user-info span {
  font-size: 13px;
  color: var(--white);
  opacity: 0.8;
}

.sidenav-nav {
  position: relative;
  z-index: 1;
  margin: 1.5rem 0;
}
.sidenav-nav li a {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--text);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  font-size: 14px;
  font-weight: 600;
}
.sidenav-nav li a i {
  margin-right: 0.75rem;
  font-size: 18px;
}
.sidenav-nav li a:hover, .sidenav-nav li a:focus {
  color: var(--primary-950);
  background-color: var(--primary-50);
}
.sidenav-nav li .night-mode-nav {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
}
.sidenav-nav li .night-mode-nav .form-check {
  padding-top: 0.25rem;
  min-height: auto;
  margin-bottom: 0;
  margin-left: auto;
}
.sidenav-nav li .night-mode-nav .form-check input {
  margin: 0;
}
.sidenav-nav li .night-mode-nav i {
  margin-right: 0.75rem;
  font-size: 18px;
}
.sidenav-nav li .night-mode-nav:hover, .sidenav-nav li .night-mode-nav:focus {
  color: var(--primary-950);
  background-color: var(--primary-50);
}
.sidenav-nav li ul {
  display: none;
  background-color: var(--primary-50);
  padding-bottom: 0.75rem;
}
.sidenav-nav li ul li a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-url .dropdown-icon {
  position: relative;
  margin-left: auto;
  z-index: -2;
}
.nav-url .dropdown-icon i {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  font-size: 16px;
  color: var(--primary-950);
  margin-right: 0;
}
.nav-url.dd-open {
  background-color: var(--primary-100);
}
.nav-url.dd-open .dropdown-icon i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.social-info-wrap {
  padding: 1.5rem 1.25rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top: 1px solid var(--primary-200);
}
.social-info-wrap a {
  position: relative;
  z-index: 1;
  display: block;
  line-height: 1;
  margin: 0 1rem;
  color: var(--text);
}
.social-info-wrap a::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--text);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  right: -19px;
}
.social-info-wrap a:last-child::after {
  display: none;
}
.social-info-wrap a:hover, .social-info-wrap a:focus {
  color: var(--primary-950);
}

.copyright-info {
  padding: 0 1.5rem 1.5rem;
  text-align: center;
}
.copyright-info p {
  margin-bottom: 0;
  font-size: 0.875rem;
}
.copyright-info p a {
  font-size: 0.875rem;
}

/* :: Footer */
.footer-nav-area {
  position: fixed !important;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  background-color: var(--white);
  width: 100%;
  height: 62px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  -webkit-box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);
          box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);
}

.footer-nav {
  background-color: var(--white);
  width: 100%;
  height: 62px;
}
.footer-nav ul {
  position: relative;
  z-index: 10;
  width: 100%;
}
.footer-nav ul li {
  position: relative;
  z-index: 1;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
}
.footer-nav ul li a {
  position: relative;
  display: block;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1;
  color: var(--primary-950);
  z-index: 1;
}
.footer-nav ul li a i {
  font-size: 1rem;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}
.footer-nav ul li a span {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  color: var(--primary-950);
  display: block;
  margin-top: 0.4rem;
  text-transform: uppercase;
}
.footer-nav ul li a:hover, .footer-nav ul li a:focus {
  color: var(--primary-950);
}
.footer-nav ul li a:hover span, .footer-nav ul li a:focus span {
  color: var(--primary-950);
}
.footer-nav ul li.active a {
  color: var(--primary-950);
}
.footer-nav ul li.active a span {
  color: var(--primary-950);
}
.footer-nav.footer-style-two li.active a {
  position: relative;
  z-index: 1;
  width: 3.5rem;
  height: 3.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--primary-200);
  margin: -25px auto 0;
  border-radius: 50%;
  color: var(--white);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.footer-nav.footer-style-two li.active a::before {
  border-radius: 50%;
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  right: 10%;
  z-index: -10;
  background-color: var(--primary-500);
  content: "";
}
.footer-nav.footer-style-three ul li a {
  background-color: var(--primary-100);
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-nav.footer-style-three ul li a:hover, .footer-nav.footer-style-three ul li a:focus {
  background-color: var(--primary-500);
  color: var(--white);
}
.footer-nav.footer-style-three ul li.active a {
  background-color: var(--primary-500);
  color: var(--white);
}
.footer-nav.footer-style-four ul li {
  text-align: center;
}
.footer-nav.footer-style-four ul li a {
  color: var(--primary-950);
}
.footer-nav.footer-style-four ul li a:hover, .footer-nav.footer-style-four ul li a:focus {
  color: var(--primary-950);
}
.footer-nav.footer-style-four ul li span {
  font-size: 12px;
  display: block;
  margin-top: 0.25rem;
  font-weight: 500;
  line-height: 1;
}
.footer-nav.footer-style-four ul li.active a {
  color: var(--primary-950);
}
.footer-nav.footer-style-five ul li {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-nav.footer-style-five ul li::after {
  width: 2rem;
  height: 3px;
  background-color: var(--primary-950);
  content: "";
  position: absolute;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  opacity: 0;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}
.footer-nav.footer-style-five ul li.active::after, .footer-nav.footer-style-five ul li:hover::after, .footer-nav.footer-style-five ul li:focus::after {
  opacity: 1;
}
.footer-nav.footer-style-six ul li {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-nav.footer-style-six ul li::after {
  width: 2rem;
  height: 3px;
  background-color: var(--primary-950);
  content: "";
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  opacity: 0;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}
.footer-nav.footer-style-six ul li.active::after, .footer-nav.footer-style-six ul li:hover::after, .footer-nav.footer-style-six ul li:focus::after {
  opacity: 1;
}
.footer-nav.footer-style-seven ul li {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-nav.footer-style-seven ul li::after {
  width: 7px;
  height: 7px;
  background-color: var(--success-500);
  border-radius: 50%;
  content: "";
  position: absolute;
  bottom: 5px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  opacity: 0;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}
.footer-nav.footer-style-seven ul li.active::after, .footer-nav.footer-style-seven ul li:hover::after, .footer-nav.footer-style-seven ul li:focus::after {
  opacity: 1;
}
.footer-nav.bg-warning ul li a i {
  color: var(--primary-950);
}

.horizontal-scroll.footer-nav ul {
  scrollbar-width: thin;
  overflow-x: scroll;
  overflow-y: hidden;
}
.horizontal-scroll.footer-nav ul li {
  padding: 0 1rem;
}

.bg-success.footer-nav ul li a,
.bg-primary.footer-nav ul li a,
.bg-secondary.footer-nav ul li a,
.bg-dark.footer-nav ul li a,
.bg-danger.footer-nav ul li a,
.bg-info.footer-nav ul li a,
.bg-warning.footer-nav ul li a {
  color: rgba(255, 255, 255, 0.6);
}
.bg-success.footer-nav ul li a:hover, .bg-success.footer-nav ul li a:focus,
.bg-primary.footer-nav ul li a:hover,
.bg-primary.footer-nav ul li a:focus,
.bg-secondary.footer-nav ul li a:hover,
.bg-secondary.footer-nav ul li a:focus,
.bg-dark.footer-nav ul li a:hover,
.bg-dark.footer-nav ul li a:focus,
.bg-danger.footer-nav ul li a:hover,
.bg-danger.footer-nav ul li a:focus,
.bg-info.footer-nav ul li a:hover,
.bg-info.footer-nav ul li a:focus,
.bg-warning.footer-nav ul li a:hover,
.bg-warning.footer-nav ul li a:focus {
  color: var(--white);
}
.bg-success.footer-nav ul li.active a,
.bg-primary.footer-nav ul li.active a,
.bg-secondary.footer-nav ul li.active a,
.bg-dark.footer-nav ul li.active a,
.bg-danger.footer-nav ul li.active a,
.bg-info.footer-nav ul li.active a,
.bg-warning.footer-nav ul li.active a {
  color: var(--white);
}

/* :: Button */
.btn {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 0.375rem;
}

.btn-group-lg > .btn,
.btn-lg {
  font-size: 1rem;
  padding: 10px 22px;
  border-radius: 0.425rem;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 12px;
  padding: 0.25rem 0.625rem;
}

.btn-group-lg > .btn.rounded-pill,
.btn-lg.rounded-pill {
  padding: 11px 22px;
}

.btn-group-sm > .btn.rounded-pill,
.btn-sm.rounded-pill {
  padding: 0.25rem 0.75rem;
}

.btn-creative {
  position: relative;
  z-index: 1;
  border: 0;
  overflow: hidden;
}
.btn-creative:hover, .btn-creative:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-creative.btn-primary::after {
  background-color: var(--dark);
}
.btn-creative.btn-secondary::after {
  background-color: var(--primary-950);
}
.btn-creative.btn-success::after {
  background-color: var(--dark);
}
.btn-creative.btn-danger::after {
  background-color: var(--dark);
}
.btn-creative.btn-warning::after {
  background-color: var(--primary-100);
}
.btn-creative.btn-info::after {
  background-color: var(--dark);
}
.btn-creative.btn-light::after {
  background-color: var(--warning-500);
}
.btn-creative.btn-dark::after {
  background-color: var(--primary-950);
}
.btn-creative::after {
  -webkit-transition-duration: 800ms;
          transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: 110%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -2;
  border-radius: 50%;
}
.btn-creative:hover::after, .btn-creative:focus::after {
  top: -40%;
}

.btn-circle {
  border: 0;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.btn-circle > i {
  font-size: 1.25rem;
  margin-right: 0;
  line-height: 1;
}

.btn-facebook {
  background-color: #1778f2;
  border-color: #1778f2;
}
.btn-facebook:hover, .btn-facebook:focus {
  background-color: #1778f2;
  border-color: #1778f2;
}

.btn-google {
  background-color: #EA4335;
  border-color: #EA4335;
}
.btn-google:hover, .btn-google:focus {
  background-color: #EA4335;
  border-color: #EA4335;
}

.btn-twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:hover, .btn-twitter:focus {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

/* :: Badge */
.badge {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.badge-avater {
  position: relative;
  z-index: 1;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  text-align: center;
  color: var(--white);
  line-height: 2rem;
  font-weight: 700;
  font-size: 14px;
}
.badge-avater:hover, .badge-avater:focus {
  color: var(--white);
}
.badge-avater img {
  display: block;
}
.badge-avater .status {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  border: 2px solid var(--white);
}
.badge-avater.badge-avater-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 10px;
  line-height: 1.5rem;
}
.badge-avater.badge-avater-sm .status {
  width: 0.5rem;
  height: 0.5rem;
  bottom: -2px;
  right: -1px;
  border: 1px solid var(--white);
}
.badge-avater.badge-avater-lg {
  width: 3rem;
  height: 3rem;
  font-size: 16px;
  line-height: 3rem;
}
.badge-avater.badge-avater-lg .status {
  width: 1rem;
  height: 1rem;
  bottom: 0;
  right: 0;
}

.badge-avater-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.badge-avater-group .badge-avater {
  margin-right: -0.75rem;
}
.badge-avater-group .badge-avater.badge-avater-sm {
  margin-right: -0.5rem;
}

/* :: Breadcrumb */
.breadcrumb-wrapper {
  background-color: var(--white);
}

.breadcrumb {
  background-color: var(--white);
}

.breadcrumb-item {
  font-weight: 500;
  font-size: 14px;
}

.breadcrumb-one {
  position: relative;
  z-index: 1;
}
.breadcrumb-one .breadcrumb-item + .breadcrumb-item::before {
  color: var(--text);
  content: "/";
}
.breadcrumb-one .breadcrumb-item {
  font-size: 14px;
  color: var(--primary-950);
}
.breadcrumb-one .breadcrumb-item a {
  color: var(--text);
}
.breadcrumb-one .breadcrumb-item a:hover, .breadcrumb-one .breadcrumb-item a:focus {
  color: var(--primary-950);
}

.breadcrumb-two {
  position: relative;
  z-index: 1;
}
.breadcrumb-two .breadcrumb-item + .breadcrumb-item::before {
  color: var(--text);
  content: "/";
}
.breadcrumb-two .breadcrumb-item {
  font-size: 14px;
  color: var(--primary-950);
}
.breadcrumb-two .breadcrumb-item a {
  color: var(--text);
}
.breadcrumb-two .breadcrumb-item a:hover, .breadcrumb-two .breadcrumb-item a:focus {
  color: var(--primary-950);
}

.breadcrumb-three {
  position: relative;
  z-index: 1;
}
.breadcrumb-three .breadcrumb-item + .breadcrumb-item::before {
  color: var(--text);
  content: "/";
}
.breadcrumb-three .breadcrumb-item {
  color: var(--primary-950);
}
.breadcrumb-three .breadcrumb-item a {
  color: var(--text);
}
.breadcrumb-three .breadcrumb-item a i {
  margin-right: 0.25rem;
  color: var(--primary-950);
}
.breadcrumb-three .breadcrumb-item a:hover, .breadcrumb-three .breadcrumb-item a:focus {
  color: var(--primary-950);
}

.breadcrumb-four {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.breadcrumb-four .breadcrumb-item + .breadcrumb-item::before {
  color: var(--white);
  content: "/";
}
.breadcrumb-four .breadcrumb-item {
  color: var(--white);
}
.breadcrumb-four .breadcrumb-item a {
  color: var(--white);
}
.breadcrumb-four .breadcrumb-item a:hover, .breadcrumb-four .breadcrumb-item a:focus {
  color: var(--primary-950);
}

.breadcrumb-colorful {
  position: relative;
  z-index: 1;
}
.breadcrumb-colorful .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.6);
  content: "/";
}
.breadcrumb-colorful .breadcrumb-item {
  font-size: 14px;
  color: var(--white);
}
.breadcrumb-colorful .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
}
.breadcrumb-colorful .breadcrumb-item a:hover, .breadcrumb-colorful .breadcrumb-item a:focus {
  color: var(--white);
}

/* :: Accordion */
.accordion-item {
  border: 0;
}

.accordion-button {
  padding: 0.75rem 1rem;
  font-size: 14px;
  color: var(--primary-950);
  border-color: var(--primary-200);
  font-weight: 500;
}

.accordion-collapse {
  border-color: var(--primary-200);
}

.accordion-style-one .accordion-item {
  margin-bottom: 0;
  border-bottom: 0;
}
.accordion-style-one .accordion-item h6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid var(--primary-200);
  color: var(--primary-950);
  cursor: pointer;
}
.accordion-style-one .accordion-item h6.collapsed {
  color: var(--primary-950);
}
.accordion-style-one .accordion-item h6 i {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  margin-right: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.accordion-style-one .accordion-item h6.collapsed i {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.accordion-style-one .accordion-item .accordion-collapse {
  border: 0 !important;
}
.accordion-style-one .accordion-item .accordion-body {
  border-bottom: 1px solid var(--primary-200);
}
.accordion-style-one .accordion-item:last-child h6 {
  border-bottom: 1px solid var(--primary-200);
}
.accordion-style-one .accordion-item:last-child h6.collapsed {
  border-bottom: 0;
}
.accordion-style-one .accordion-item:last-child .accordion-body {
  border-bottom: 0;
}

.accordion-style-two .accordion-item {
  margin-bottom: 0;
  border-bottom: 0;
}
.accordion-style-two .accordion-item h6 {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--primary-100);
  color: var(--danger-500);
  cursor: pointer;
}
.accordion-style-two .accordion-item h6.collapsed {
  color: var(--primary-950);
}
.accordion-style-two .accordion-item h6 i {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--danger-500);
  color: var(--white);
  font-size: 14px;
  text-align: center;
  line-height: 1.5rem;
  border-radius: 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.accordion-style-two .accordion-item h6.collapsed i {
  background-color: var(--primary-950);
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.accordion-style-two .accordion-item .accordion-collapse {
  border: 0 !important;
}
.accordion-style-two .accordion-item .accordion-body {
  border-bottom: 1px solid var(--primary-100);
}
.accordion-style-two .accordion-item:last-child h6 {
  border-bottom: 1px solid var(--primary-100);
}
.accordion-style-two .accordion-item:last-child h6.collapsed {
  border-bottom: 0;
}
.accordion-style-two .accordion-item:last-child .accordion-body {
  border-bottom: 0;
}

.accordion-style-three .accordion-item {
  margin-bottom: 0.25rem;
  border: 0;
}
.accordion-style-three .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style-three .accordion-item h6 {
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
  padding: 0.375rem 1rem;
  border-bottom: 1px solid var(--primary-200);
  color: var(--primary-950);
  cursor: pointer;
  font-size: 14px;
}
.accordion-style-three .accordion-item h6.collapsed {
  color: var(--primary-950);
}
.accordion-style-three .accordion-item h6 i {
  font-size: 1rem;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  margin-right: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.accordion-style-three .accordion-item h6.collapsed i {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.accordion-style-three .accordion-item .accordion-collapse {
  border: 0 !important;
}
.accordion-style-three .accordion-item .accordion-body {
  padding: 0.5rem;
}
.accordion-style-three .accordion-item:last-child h6 {
  border-bottom: 0;
}

.accordion-style-four .accordion-item {
  margin-bottom: 0;
}
.accordion-style-four .accordion-item h6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
  padding: 0.5rem 0;
  color: var(--warning-500);
  cursor: pointer;
}
.accordion-style-four .accordion-item h6.collapsed {
  color: var(--white);
}
.accordion-style-four .accordion-item h6 i {
  font-size: 18px;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  margin-right: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.accordion-style-four .accordion-item h6.collapsed i {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.accordion-style-four .accordion-item .accordion-collapse {
  border: 0 !important;
}
.accordion-style-four .accordion-item .accordion-body {
  padding: 0;
  padding-bottom: 0.5rem;
}
.accordion-style-four .accordion-item .accordion-body p {
  color: var(--primary-200);
}
.accordion-style-four .accordion-item:last-child h6 {
  border-bottom: 0;
}

.accordion-bg-primary {
  background-color: var(--primary-100);
  border-left: 4px solid var(--primary-500);
}

.accordion-bg-danger {
  background-color: rgba(220, 53, 69, 0.1);
  border-left: 4px solid var(--danger-500);
}

.accordion-bg-warning {
  background-color: rgba(255, 193, 7, 0.1);
  border-left: 4px solid var(--warning-500);
}

.accordion-bg-info {
  background-color: rgba(13, 202, 240, 0.1);
  border-left: 4px solid var(--info-500);
}

.accordion-bg-success {
  background-color: rgba(25, 135, 84, 0.1);
  border-left: 4px solid var(--success-500);
}

.accordion-style-five .accordion-item {
  border-radius: 0.25rem;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
}
.accordion-style-five .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style-five .accordion-item h6 {
  cursor: pointer;
  margin-bottom: 0;
  color: var(--primary-950);
}
.accordion-style-five .accordion-item h6.collapsed {
  color: var(--primary-950);
}
.accordion-style-five .accordion-item h6 i {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  margin-right: 0.5rem;
  font-size: 1.25rem;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.accordion-style-five .accordion-item h6.collapsed i {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.accordion-style-five .accordion-collapse {
  border-color: transparent;
  padding-left: 1.75rem;
}

.accordion-style-six .accordion-item {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0.375rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-size: cover;
}
.accordion-style-six .accordion-item::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  content: "";
  background-color: rgba(13, 13, 53, 0.75);
  border-radius: 0.375rem;
}
.accordion-style-six .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style-six .accordion-item h6 {
  cursor: pointer;
  margin-bottom: 0;
  color: var(--white);
}
.accordion-style-six .accordion-item h6.collapsed {
  color: var(--white);
}

/* :: Alert */
.alert {
  padding: 0.625rem 0.75rem;
  font-size: 14px;
  border-radius: 0.4rem;
}

.alert-dismissible .btn-close {
  font-size: 10px;
}

.custom-alert-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1rem;
  padding-left: 27px;
  font-size: 14px;
  border-color: var(--primary-100);
}
.custom-alert-one::after {
  content: "";
  position: absolute;
  width: 4px;
  height: calc(100% - 30px);
  top: 15px;
  left: 12px;
  z-index: 1;
  border-radius: 1rem;
}
.custom-alert-one.alert-dismissible .close {
  position: relative;
  padding: 0.25rem;
  margin-left: 0.75rem;
  margin-left: auto;
}
.custom-alert-one i {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.custom-alert-one.alert-primary {
  color: var(--primary-500);
  background-color: transparent;
}
.custom-alert-one.alert-primary::after {
  background-color: var(--primary-700);
}
.custom-alert-one.alert-secondary {
  color: var(--secondary-500);
  background-color: transparent;
}
.custom-alert-one.alert-secondary::after {
  background-color: var(--secondary-700);
}
.custom-alert-one.alert-success {
  color: var(--success-500);
  background-color: transparent;
}
.custom-alert-one.alert-success::after {
  background-color: var(--success-700);
}
.custom-alert-one.alert-danger {
  color: var(--danger-500);
  background-color: transparent;
}
.custom-alert-one.alert-danger::after {
  background-color: var(--danger-700);
}
.custom-alert-one.alert-warning {
  color: var(--warning-500);
  background-color: transparent;
}
.custom-alert-one.alert-warning::after {
  background-color: var(--warning-700);
}
.custom-alert-one.alert-info {
  color: var(--info-500);
  background-color: transparent;
}
.custom-alert-one.alert-info::after {
  background-color: var(--info-700);
}
.custom-alert-one.alert-light {
  color: var(--text);
  background-color: transparent;
}
.custom-alert-one.alert-light::after {
  background-color: var(--secondary-500);
}
.custom-alert-one.alert-dark {
  color: var(--primary-950);
  background-color: transparent;
}
.custom-alert-one.alert-dark::after {
  background-color: var(--dark);
}

.custom-alert-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1rem;
  font-size: 14px;
  color: var(--white);
  border-color: transparent;
}
.custom-alert-two.alert-dismissible .close {
  position: relative;
  padding: 0.25rem;
  margin-left: 0.75rem;
  margin-left: auto;
}
.custom-alert-two i {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.custom-alert-two.alert-primary {
  background-color: var(--primary-950);
}
.custom-alert-two.alert-secondary {
  background-color: var(--secondary-500);
}
.custom-alert-two.alert-success {
  background-color: var(--success-500);
}
.custom-alert-two.alert-danger {
  background-color: var(--danger-500);
}
.custom-alert-two.alert-warning {
  background-color: var(--warning-500);
}
.custom-alert-two.alert-info {
  background-color: var(--info-500);
}
.custom-alert-two.alert-light {
  color: var(--text);
  background-color: var(--primary-100);
}
.custom-alert-two.alert-dark {
  background-color: var(--dark);
}

.custom-alert-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  font-size: 13px;
}
.custom-alert-three.alert-dismissible .close {
  position: relative;
  padding: 0.25rem;
  margin-left: 0.75rem;
  margin-left: auto;
}
.custom-alert-three i {
  font-size: 1.5rem;
  margin-right: 0.75rem;
  margin-top: 0.25rem;
  line-height: 1;
}
.custom-alert-three .alert-text h6 {
  margin-bottom: 4px;
  color: inherit;
}
.custom-alert-three .alert-text span {
  display: block;
  font-size: 12px;
  color: var(--text);
}

/* Dividers */
.divider {
  width: 100%;
  height: 2px;
  border-top: 2px solid var(--primary-950);
  position: relative;
  z-index: 1;
  margin: 1rem 0;
  clear: both;
}
.divider.divider-dotted {
  border-top-style: dotted;
}
.divider.divider-dotted {
  border-top-style: dotted;
}
.divider.divider-dashed {
  border-top-style: dashed;
}
.divider.divider-center-icon {
  margin: 2rem 0;
}
.divider.divider-center-icon > i {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #212729;
  color: var(--white);
  text-align: center;
  line-height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.divider.divider-center-icon.border-primary i {
  background-color: var(--primary-950);
}
.divider.divider-center-icon.border-secondary i {
  background-color: var(--secondary-500);
}
.divider.divider-center-icon.border-success i {
  background-color: var(--success-500);
}
.divider.divider-center-icon.border-danger i {
  background-color: var(--danger-500);
}
.divider.divider-center-icon.border-warning i {
  background-color: var(--warning-500);
}
.divider.divider-center-icon.border-info i {
  background-color: var(--info-500);
}
.divider.divider-center-icon.border-light i {
  background-color: var(--primary-100);
  color: var(--primary-950);
  border: 1px solid var(--primary-200);
  line-height: 30px;
}
.divider.divider-center-icon.border-dark i {
  background-color: var(--dark);
}
.divider.divider-center-icon.border-white i {
  background-color: var(--white);
  color: var(--primary-950);
  border: 1px solid var(--primary-200);
  line-height: 30px;
}

/* :: Coming Soon */
.coming-soon-wrapper {
  position: relative;
  height: 100vh !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.coming-soon-wrapper .countdown2 span {
  font-size: 1.25rem;
}

.cs-logo a {
  display: block;
  margin-bottom: 2.75rem;
}
.cs-logo a img {
  max-height: 2rem;
}

.cs-newsletter-form .modal-body {
  padding: 2rem;
}
.cs-newsletter-form .btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

/* :: Card */
.card {
  border: 0;
  border-radius: 6px;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.card-bg-img.bg-overlay::after {
  border-radius: 0.4rem;
}

.card-gradient-bg {
  background: #0cebeb;
  background: -webkit-gradient(linear, left top, right top, from(#29ffc6), color-stop(#20e3b2), to(#0cebeb));
  background: linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
}

.card-body {
  padding: 1.5rem;
  color: var(--primary-950);
}

.cta-card {
  position: relative;
  z-index: 1;
}
.cta-card.bg-overlay::after {
  opacity: 0.8;
}

.card-badge {
  top: 1.5rem;
  left: 1.5rem;
}

.card-img-wrap {
  position: relative;
  z-index: 1;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100px;
          flex: 0 0 100px;
  width: 100px;
  max-width: 100px;
  border-radius: 50%;
  margin-right: 1rem;
}
.card-img-wrap img {
  border-radius: 50%;
}

.card-round {
  border-radius: 50rem !important;
}

/* Chat */
.chat-user-status-slides {
  position: relative;
  z-index: 1;
}
.chat-user-status-slides .user-status-slide a {
  margin: 0.5rem 0;
  padding: 0.5rem 0.25rem 0.375rem;
  background-color: var(--white);
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 2px 1px #d7def4;
          box-shadow: 0 1px 2px 1px #d7def4;
  display: block;
  position: relative;
  z-index: 1;
  width: 3.75rem;
  text-align: center;
}
.chat-user-status-slides .user-status-slide a img {
  width: 2rem;
  border-radius: 50%;
  margin: 0 auto;
}
.chat-user-status-slides .user-status-slide a .active-status {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  top: 0.5rem;
  right: 0.5rem;
  border: 2px solid var(--white);
  background-color: var(--success-500);
}
.chat-user-status-slides .user-status-slide a p {
  font-size: 11px;
}
.chat-user-status-slides .user-status-slide.offline a .active-status {
  background-color: var(--secondary-500);
}

.add-new-contact-wrap a {
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1000;
  background-color: var(--primary-950);
  text-align: center;
  line-height: 2.25rem;
  border-radius: 50%;
  bottom: 82px;
  right: 20px;
  color: var(--white);
}
.add-new-contact-wrap a i {
  line-height: 2.5rem;
}

.add-new-contact-modal .modal-body textarea.form-control {
  min-height: 90px;
}

.chat-user-info {
  width: calc(100% - 60px);
}

.chat-user-list {
  border-radius: 0.4rem;
}

.chat-user-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.chat-user-list li:hover, .chat-user-list li:focus {
  background-color: var(--primary-100);
}
.chat-user-list li .chat-options-btn {
  line-height: 1;
}
.chat-user-list li .chat-options-btn .dropdown-toggle::before {
  display: none;
}
.chat-user-list li .chat-options-btn .btn {
  padding: 0 !important;
  color: var(--text);
  border: 0 !important;
}
.chat-user-list li .chat-options-btn .btn.show {
  border: 0 !important;
}
.chat-user-list li .chat-options-btn .btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.chat-user-list li .chat-options-btn .dropdown-menu {
  min-width: 6.5rem;
  padding: 0.5rem 0.75rem;
  border: 0;
}
.chat-user-list li .chat-options-btn .dropdown-menu li {
  display: block;
}
.chat-user-list li .chat-options-btn .dropdown-menu li:hover, .chat-user-list li .chat-options-btn .dropdown-menu li:focus {
  background-color: var(--white);
}
.chat-user-list li .chat-options-btn .dropdown-menu a {
  color: var(--text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0;
  font-size: 12px;
}
.chat-user-list li .chat-options-btn .dropdown-menu a i {
  margin-right: 0.25rem;
  font-size: 14px;
  min-width: 1rem;
}
.chat-user-list li .chat-options-btn .dropdown-menu a:hover, .chat-user-list li .chat-options-btn .dropdown-menu a:focus {
  color: var(--primary-950);
}
.chat-user-list li a {
  width: 94%;
}
.chat-user-list li:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.chat-user-list li:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom: 0;
}
.chat-user-list li .chat-user-thumbnail {
  position: relative;
  z-index: 1;
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--primary-200);
  border-radius: 50%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.25rem;
          flex: 0 0 2.25rem;
}
.chat-user-list li .chat-user-thumbnail .active-status {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  bottom: 1px;
  right: 0;
  border: 2px solid var(--white);
  background-color: var(--success-500);
}
.chat-user-list li .chat-user-info h6 {
  font-size: 14px;
  color: var(--text);
}
.chat-user-list li .chat-user-info .last-chat p {
  font-size: 12px;
}
.chat-user-list li.chat-unread .chat-user-info .last-chat p {
  color: var(--primary-950);
  font-weight: 500;
}
.chat-user-list li.offline .chat-user-thumbnail .active-status {
  background-color: var(--secondary-500);
}

#chat-wrapper {
  min-height: calc(100vh - 112px);
}
#chat-wrapper.calling-screen-active {
  max-height: calc(100vh - 115px) !important;
}

.chat-user--info .user-thumbnail-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 0.375rem;
}
.chat-user--info .user-thumbnail-name img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.chat-user--info .user-thumbnail-name p {
  color: var(--primary-950);
  display: block;
  line-height: 1;
  margin-bottom: 2px;
  font-weight: 500;
}
.chat-user--info .user-thumbnail-name .active-status,
.chat-user--info .user-thumbnail-name .offline-status {
  line-height: 1;
  display: block;
  color: var(--success-500);
  font-size: 11px;
}

.single-chat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
}
.single-chat-item:last-child {
  margin-bottom: 0;
}
.single-chat-item .user-avatar {
  position: relative;
  z-index: 1;
  margin-right: 0.5rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2rem;
          flex: 0 0 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--white);
}
.single-chat-item .user-avatar .name-first-letter {
  position: absolute;
  font-size: 1rem;
  font-weight: 700;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -5;
  line-height: 1;
  color: var(--primary-950);
}
.single-chat-item .user-avatar img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.single-chat-item .user-message {
  max-width: 22rem;
}
.single-chat-item .user-message .message-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.25rem;
}
.single-chat-item .user-message .download-file-wrap {
  padding: 12px;
  border-radius: 18px;
  background-color: var(--white);
}
.single-chat-item .user-message .download-file-wrap .download-avatar {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 3rem;
          flex: 0 0 3rem;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  color: var(--primary-950);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.single-chat-item .user-message .download-file-wrap .download-avatar .dl-icon i {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}
.single-chat-item .user-message .download-file-wrap .download-avatar .download-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}
.single-chat-item .user-message .download-file-wrap .download-file-info .file-name {
  font-size: 14px;
  font-weight: 500;
  max-width: 160px;
}
.single-chat-item .user-message .download-file-wrap .download-file-info .file-size {
  font-size: 12px;
}
.single-chat-item .user-message .download-file-wrap:hover .download-avatar .dl-icon i, .single-chat-item .user-message .download-file-wrap:focus .download-avatar .dl-icon i {
  opacity: 0;
}
.single-chat-item .user-message .download-file-wrap:hover .download-avatar .download-btn, .single-chat-item .user-message .download-file-wrap:focus .download-avatar .download-btn {
  opacity: 1;
  visibility: visible;
}
.single-chat-item .user-message .single-message {
  display: block;
}
.single-chat-item .user-message .single-message p {
  padding: 8px 12px;
  background-color: var(--white);
  border-radius: 18px;
  font-size: 14px;
  margin-bottom: 0;
}
.single-chat-item .user-message .single-message img {
  border-radius: 12px;
  max-width: 120px;
}
.single-chat-item .user-message .single-message .typing {
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 18px 15px 14px;
  border-radius: 60px;
}
.single-chat-item .user-message .single-message .typing .dot {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 0.375rem;
          flex: 0 0 0.375rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: var(--primary-200);
  -webkit-animation: typingg 1000ms linear 0s infinite;
          animation: typingg 1000ms linear 0s infinite;
  margin: 0 0.25rem;
}
.single-chat-item .user-message .single-message .typing .dot:nth-child(2) {
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}
.single-chat-item .user-message .single-message .typing .dot:nth-child(3) {
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}
.single-chat-item .user-message .message-time-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1;
  margin-top: 0.25rem;
}
.single-chat-item .user-message .message-time-status .sent-time {
  font-size: 10px;
  font-weight: 500;
  margin-left: 0.5rem;
}
.single-chat-item .user-message .message-time-status .sent-status {
  font-size: 10px;
  width: 12px;
  height: 12px;
  margin-left: 0.25rem;
  text-align: center;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--primary-950);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}
.single-chat-item .user-message .message-time-status .sent-status i {
  line-height: 12px;
}
.single-chat-item .user-message .message-time-status .sent-status.delivered {
  color: var(--primary-950);
}
.single-chat-item .user-message .message-time-status .sent-status.seen {
  background-color: var(--success-500);
  color: var(--white);
}
.single-chat-item .user-message .dropstart {
  line-height: 1;
  margin-top: -2px;
}
.single-chat-item .user-message .btn-options {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 12px;
          flex: 0 0 12px;
  width: 12px;
  padding: 0 !important;
  margin-right: 0.5rem;
  color: var(--text);
  line-height: 1;
  border: 0 !important;
}
.single-chat-item .user-message .btn-options.show {
  border: 0 !important;
}
.single-chat-item .user-message .btn-options:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: var(--primary-950);
}
.single-chat-item .user-message .btn-options.dropdown-toggle::after, .single-chat-item .user-message .btn-options.dropdown-toggle::before {
  display: none !important;
}
.single-chat-item .user-message .dropdown-menu {
  min-width: 6.5rem;
  padding: 0.5rem 0.75rem;
  border: 0;
}
.single-chat-item .user-message .dropdown-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.single-chat-item .user-message .dropdown-menu li:last-child {
  border-bottom: 0;
}
.single-chat-item .user-message .dropdown-menu a {
  color: var(--text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.25rem 0;
  font-size: 12px;
}
.single-chat-item .user-message .dropdown-menu a i {
  margin-right: 0.25rem;
  font-size: 1rem;
  min-width: 1rem;
}
.single-chat-item .user-message .dropdown-menu a i.bi-trash {
  font-size: 14px;
}
.single-chat-item .user-message .dropdown-menu a:hover, .single-chat-item .user-message .dropdown-menu a:focus {
  color: var(--primary-950);
}
.single-chat-item.outgoing {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.single-chat-item.outgoing .user-avatar {
  margin-right: 0;
  margin-left: 0.5rem;
}
.single-chat-item.outgoing .user-message {
  text-align: right;
  max-width: 22rem;
}
.single-chat-item.outgoing .user-message .message-content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-bottom: 0.25rem;
}
.single-chat-item.outgoing .user-message .message-content:last-child {
  margin-bottom: 0;
}
.single-chat-item.outgoing .user-message .single-message p {
  background-color: var(--primary-950);
  color: var(--white);
}
.single-chat-item.outgoing .user-message .message-time-status {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.single-chat-item.outgoing .dropdown-menu {
  background-color: var(--primary-950);
}
.single-chat-item.outgoing .dropdown-menu a {
  color: var(--white);
}
.single-chat-item.outgoing .dropdown-menu a:hover, .single-chat-item.outgoing .dropdown-menu a:focus {
  color: var(--warning-500);
}

.chat-footer {
  position: fixed;
  width: 100%;
  height: 62px;
  background-color: var(--white);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.chat-footer form {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100% !important;
}
.chat-footer form .form-control {
  background-color: var(--primary-100);
  border-color: var(--primary-100);
  color: var(--primary-950);
  font-size: 14px;
}
.chat-footer .btn-emoji,
.chat-footer .btn-add-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 2rem;
  padding: 0 !important;
  color: var(--text);
  line-height: 1;
  border: 0 !important;
  font-size: 1.125rem;
}
.chat-footer .btn-emoji:focus,
.chat-footer .btn-add-file:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: var(--primary-950);
  border: 0 !important;
}
.chat-footer .btn-emoji.dropdown-toggle::after,
.chat-footer .btn-add-file.dropdown-toggle::after {
  display: none !important;
}
.chat-footer .btn-submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2rem;
          flex: 0 0 2rem;
  width: 2rem;
  height: 2rem;
  padding: 0 !important;
  background-color: var(--primary-950);
  color: var(--white);
  border-radius: 50%;
  border: 0 !important;
  font-size: 1.125rem;
}
.chat-footer .btn-submit:hover, .chat-footer .btn-submit:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: var(--white);
}
.chat-footer .dropdown-menu {
  min-width: 7rem;
  padding: 0.5rem 0.75rem;
  border: 0;
}
.chat-footer .dropdown-menu a {
  color: var(--text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.25rem 0;
}
.chat-footer .dropdown-menu a i {
  margin-right: 0.25rem;
  font-size: 18px;
  min-width: 18px;
}
.chat-footer .dropdown-menu a i.bi-trash {
  font-size: 16px;
}
.chat-footer .dropdown-menu a:hover, .chat-footer .dropdown-menu a:focus {
  color: var(--primary-950);
}

.video-calling-popup-wrap {
  position: fixed;
  background-color: rgba(6, 18, 56, 0.9);
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  visibility: hidden;
}
.video-calling-popup-wrap.screen-active {
  opacity: 1;
  visibility: visible;
}
.video-calling-popup-wrap .video-calling-popup-body {
  position: relative;
  max-width: 300px;
  left: 0;
  background: var(--white);
  padding: 2rem 1rem;
  width: 80%;
  text-align: center;
  border-radius: 2rem;
  background-size: cover;
  background-position: center center;
}
.video-calling-popup-wrap .video-calling-popup-body::after {
  border-radius: 2rem;
}
.video-calling-popup-wrap .video-calling-popup-body .user-thumbnail {
  position: relative;
  z-index: 1;
}
.video-calling-popup-wrap .video-calling-popup-body .user-thumbnail img {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  border-radius: 50%;
}
.video-calling-popup-wrap .video-calling-popup-body .video-icon {
  font-size: 2.5rem;
}

.calling-popup-wrap {
  position: fixed;
  background-color: rgba(6, 18, 56, 0.9);
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  visibility: hidden;
}
.calling-popup-wrap.screen-active {
  opacity: 1;
  visibility: visible;
}
.calling-popup-wrap .calling-popup-body {
  position: relative;
  max-width: 300px;
  left: 0;
  background: var(--white);
  padding: 2rem 1rem;
  width: 80%;
  text-align: center;
  border-radius: 2rem;
  background-size: cover;
  background-position: center center;
}
.calling-popup-wrap .calling-popup-body::after {
  border-radius: 2rem;
}
.calling-popup-wrap .calling-popup-body .user-thumbnail {
  position: relative;
  z-index: 1;
}
.calling-popup-wrap .calling-popup-body .user-thumbnail img {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  border-radius: 50%;
}
.calling-popup-wrap .calling-popup-body .call-icon {
  font-size: 2rem;
}

.video-call-screen {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-size: cover;
}
.video-call-screen .call-back-button a {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  top: 1.5rem;
  left: 1.5rem;
  z-index: 10;
  color: var(--text);
  font-size: 1.5rem;
  text-align: center;
}
.video-call-screen .call-back-button a i {
  line-height: 1.5rem;
}
.video-call-screen .call-btn-group {
  position: absolute;
  z-index: 10;
  bottom: 3rem;
  width: 210px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  left: 50%;
  margin-left: -105px;
}
.video-call-screen .call-btn-group a > i {
  font-size: 1rem;
}
.video-call-screen .call-btn-group .btn-call-cancel {
  padding: 9px !important;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0.5rem rgba(225, 83, 97, 0.5);
          box-shadow: 0 0 0 0.5rem rgba(225, 83, 97, 0.5);
}

/* :: Carousel */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
  width: 24px;
  height: 24px;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
  width: 24px;
  height: 24px;
}

.carousel-indicators button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
.carousel-indicators button.active {
  background-color: var(--warning-500);
}

/* :: Tiny Slider One CSS */
.tiny-slider-one-wrapper {
  position: relative;
  z-index: 1;
}
.tiny-slider-one-wrapper .single-hero-slide {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 220px;
  background-position: center center;
  background-size: cover;
}
@media only screen and (min-width:480px) and (max-width:767px) {
  .tiny-slider-one-wrapper .single-hero-slide {
    height: 220px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .tiny-slider-one-wrapper .single-hero-slide {
    height: 240px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .tiny-slider-one-wrapper .single-hero-slide {
    height: 300px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .tiny-slider-one-wrapper .single-hero-slide {
    height: 320px;
  }
}
@media only screen and (min-width:1200px) and (max-width:1399px) {
  .tiny-slider-one-wrapper .single-hero-slide {
    height: 450px;
  }
}
.tiny-slider-one-wrapper .single-hero-slide .slide-content {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}
.tiny-slider-one-wrapper .single-hero-slide .slide-content p {
  font-size: 14px;
}
.tiny-slider-one-wrapper .tns-controls {
  position: absolute;
  z-index: 100;
  bottom: 1.25rem;
  right: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tiny-slider-one-wrapper .tns-controls button[data-controls=prev],
.tiny-slider-one-wrapper .tns-controls button[data-controls=next] {
  outline: none;
  background-color: rgba(255, 255, 255, 0.1);
  width: 26px;
  height: 26px;
  text-align: center;
  color: var(--white);
  border-radius: 50%;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  font-size: 12px;
  border: none;
}
.tiny-slider-one-wrapper .tns-controls button[data-controls=prev] i,
.tiny-slider-one-wrapper .tns-controls button[data-controls=next] i {
  line-height: 26px;
}
.tiny-slider-one-wrapper .tns-controls button[data-controls=prev]:hover, .tiny-slider-one-wrapper .tns-controls button[data-controls=prev]:focus,
.tiny-slider-one-wrapper .tns-controls button[data-controls=next]:hover,
.tiny-slider-one-wrapper .tns-controls button[data-controls=next]:focus {
  background-color: var(--warning-500);
  color: var(--primary-950);
}
.tiny-slider-one-wrapper .tns-controls button[data-controls=next] {
  margin-left: 0.5rem;
}
.tiny-slider-one-wrapper .tns-nav {
  position: absolute;
  bottom: 1.375rem;
  left: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tiny-slider-one-wrapper .tns-nav button {
  outline: none;
  border: none;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  position: relative;
  z-index: 1;
  margin-right: 0.375rem;
  width: 0.5rem;
  height: 0.5rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 0.5rem;
          flex: 0 0 0.5rem;
  max-width: 0.5rem;
  background-color: var(--primary-200);
  border-radius: 50%;
}
.tiny-slider-one-wrapper .tns-nav button.tns-nav-active {
  background-color: var(--warning-500);
}

/* :: Tiny Slider Two CSS */
.tiny-slider-two-wrapper {
  position: relative;
  z-index: 1;
}
.tiny-slider-two-wrapper .single-hero-slide {
  height: 200px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1rem;
}
.tiny-slider-two-wrapper .single-hero-slide.bg-overlay::after {
  border-radius: 1rem;
}
.tiny-slider-two-wrapper .tns-nav {
  position: absolute;
  right: 3.625rem;
  bottom: 1.5rem;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tiny-slider-two-wrapper .tns-nav button {
  outline: none;
  background-color: transparent;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  line-height: 1;
  border: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 700;
}
.tiny-slider-two-wrapper .tns-nav button.tns-nav-active {
  color: var(--warning-500);
  opacity: 1;
  visibility: visible;
}
.tiny-slider-two-wrapper #totaltnsDotsCount {
  position: absolute;
  z-index: 1;
  bottom: 1.5rem;
  right: 1.5rem;
  color: var(--warning-500);
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
}
.tiny-slider-two-wrapper #totaltnsDotsCount::before {
  width: 1rem;
  height: 3px;
  background-color: var(--white);
  position: absolute;
  border-radius: 8px;
  content: "";
  left: -1.25rem;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* :: Tiny Slider Three CSS */
.tiny-slider-three-wrapper {
  position: relative;
  z-index: 1;
}
.tiny-slider-three-wrapper .single-hero-slide {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
}
.tiny-slider-three-wrapper .single-hero-slide.bg-overlay::after {
  border-radius: 1rem;
  opacity: 0.6;
}

/* :: Countdown */
.countdown1 {
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.countdown1 > div {
  margin-right: 1rem;
}
.countdown1 > div:last-child {
  margin-right: 0;
}
.countdown1 > div span {
  font-weight: 600;
  color: var(--primary-950);
  display: block;
  line-height: 1;
}
.countdown1 > div .smalltext {
  font-weight: 500;
  font-size: 12px;
  display: block;
}

.countdown2 {
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.countdown2 > div {
  color: var(--white);
  font-size: 1rem;
  margin-left: 1rem;
}
.countdown2 > div:first-child {
  margin-left: 0;
}
.countdown2 > div span {
  position: relative;
  z-index: 1;
}
.countdown2 > div span::before {
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 12px;
  background-color: var(--warning-500);
  content: "";
  bottom: -2px;
  left: 0;
  z-index: 10;
}
.countdown2 > div .smalltext {
  margin-top: 0.25rem;
  font-size: 12px;
}

.coming-soon-card .icon-wrap {
  display: block;
  margin: 0 auto 1rem;
  width: 4rem;
  height: 4rem;
  background-color: var(--white);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.coming-soon-card .icon-wrap i {
  font-size: 2.25rem;
  line-height: 4rem;
}
.coming-soon-card .countdown3 {
  color: var(--white);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.coming-soon-card .countdown3 > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 0.25rem;
  padding: 0.25rem 0.375rem;
  margin-right: 0.25rem;
  line-height: 1;
}
.coming-soon-card .countdown3 > div:last-child {
  margin-right: 0;
}
.coming-soon-card .countdown3 > div span {
  font-size: 13px;
  font-weight: 500;
}
.coming-soon-card .countdown3 > div .smalltext {
  font-size: 12px;
  margin-left: 4px;
}

/* :: Counterup */
.single-counter-wrap {
  position: relative;
  z-index: 1;
}
.single-counter-wrap .solid-line {
  display: block;
  width: 2rem;
  margin: 0.5rem auto 0.75rem;
  height: 0.25rem;
  border-radius: 12px;
  background-color: var(--primary-950);
}
.single-counter-wrap > i {
  font-size: 1.5rem;
}

/* :: Form */
.form-check-input[type=checkbox].indeterminate {
  background-color: var(--primary-950);
  border-color: var(--primary-950);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-check-label {
  font-size: 14px;
  color: var(--primary-950);
  font-weight: 500;
}

.form-check-input.form-check-success:checked {
  background-color: var(--success-500);
  border-color: var(--success-500);
}

.form-check-input.form-check-danger:checked {
  background-color: var(--danger-500);
  border-color: var(--danger-500);
}

.form-check-input.form-check-warning:checked {
  background-color: var(--warning-500);
  border-color: var(--warning-500);
}

.form-check-input.form-check-info:checked {
  background-color: var(--info-500);
  border-color: var(--info-500);
}

.form-check-input.form-check-secondary:checked {
  background-color: var(--secondary-500);
  border-color: var(--secondary-500);
}

.form-check-input.form-check-light:checked {
  background-color: var(--primary-100);
  border-color: var(--primary-100);
}

.form-check-input.form-check-dark:checked {
  background-color: var(--dark);
  border-color: var(--dark);
}

.form-check-input-lg {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0;
}
.form-check-input-lg ~ .form-check-label {
  font-size: 1rem;
  margin-left: 0.5rem;
}

.form-group {
  line-height: 1;
  margin-bottom: 1rem;
}

.form-label {
  font-size: 14px;
  color: var(--text);
  font-weight: 500;
}

.form-select {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  border: 1px solid;
  padding: 0.5rem 1rem;
  min-height: 40px;
  font-size: 14px;
  color: var(--primary-950);
  background-color: var(--white);
  border-color: var(--primary-200);
}
.form-select option {
  padding: 0.25rem 0.5rem;
  outline: none;
}
.form-select.form-control-clicked {
  background-color: var(--primary-50);
  border-color: var(--primary-50);
  color: var(--primary-950);
}
.form-select:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-color: var(--primary-200);
  color: var(--primary-950);
  background-color: var(--white);
}
.form-select.form-select-lg {
  min-height: 50px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.form-select.form-select-sm {
  min-height: 2rem;
  padding: 0.375rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.375rem;
  padding-right: 2rem !important;
}

.form-control {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  border-style: solid;
  padding: 0.5rem 1rem;
  height: 41px;
  max-height: 41px;
  font-size: 14px;
  color: var(--primary-950);
  background-color: var(--white);
  border-width: 1px;
  border-color: var(--primary-200);
  border-radius: 0.375rem;
}
.form-control.form-control-clicked {
  background-color: var(--primary-50);
  border-color: var(--primary-50);
  color: var(--primary-950);
}
.form-control:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-color: var(--primary-200);
  color: var(--primary-950);
  background-color: var(--white);
}
.form-control.form-control-lg {
  height: 50px;
  min-height: 50px;
  padding: 0.625rem 1rem;
  font-size: 16px;
}
.form-control.form-control-sm {
  height: 32px;
  padding: 0.375rem 0.5rem;
  font-size: 12px;
}

textarea.form-control {
  min-height: 120px;
}

.form-control-plaintext {
  font-size: 14px;
}

.form-file-label {
  border-color: var(--primary-200);
  font-weight: 500;
}

.form-file-input:focus,
.form-file:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none;
}

.file-upload-card {
  position: relative;
  z-index: 1;
  border: 2px dashed var(--primary-950);
  text-align: center;
  padding: 2rem;
  border-radius: 0.4rem;
  background-color: rgba(1, 52, 212, 0.1);
}

.single-plan-check {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  margin-bottom: 0.5rem;
  border: 1px solid var(--primary-200);
  padding: 1rem;
  border-radius: 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.single-plan-check:last-child {
  margin-bottom: 0;
}
.single-plan-check.active {
  border-color: var(--primary-950);
}

.autocomplete-items {
  margin-top: 0.25rem;
}
.autocomplete-items > div {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  padding: 0.5rem 0.75rem;
  background-color: var(--white);
  font-size: 14px;
  cursor: pointer;
  color: var(--primary-950);
  border-radius: 0.25rem;
}
.autocomplete-items > div:hover {
  background-color: var(--primary-100);
}
.autocomplete-items > div strong {
  color: var(--primary-950);
}

.form-control-color {
  min-width: 4rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--primary-200);
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #cfe2ff;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--primary-950);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--primary-950);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

input[type=color].form-control.is-valid, input[type=color].form-control:valid {
  background-image: none;
}

.valid-feedback,
.invalid-feedback {
  margin-left: 0.25rem;
  font-size: 12px;
}

.input-group-text {
  font-size: 14px;
}

.dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dropdown-menu li a {
  font-size: 14px;
}

.goto-page-form {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
          flex: 0 0 60%;
  max-width: 60%;
  width: 60%;
}
.goto-page-form label {
  font-size: 12px;
}
.goto-page-form .form-control {
  max-width: 40px;
  text-align: center;
}

form .btn {
  height: 41px;
}
form .btn-sm {
  height: 32px;
}
form .btn-lg {
  height: 50px;
}

/* :: Hero Block */
.hero-block-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 100vh !important;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.hero-block-content {
  position: relative;
  padding: 3rem 0;
  width: 100%;
  z-index: 10;
}
.hero-block-content img {
  max-height: 15rem;
}
.hero-block-content h2 {
  font-weight: 500;
}
.hero-block-content p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.hero-block-styles .hb-styles1 {
  position: absolute;
  width: 30px;
  height: 40px;
  top: 2rem;
  left: 2rem;
  background-repeat: repeat;
  opacity: 0.6;
  z-index: -10;
}
.hero-block-styles .hb-styles2 {
  position: absolute;
  width: 5rem;
  height: 5rem;
  border: 1rem solid var(--white);
  opacity: 0.1;
  top: 10%;
  right: -3rem;
  z-index: -10;
  border-radius: 50%;
}
.hero-block-styles .hb-styles3 {
  position: absolute;
  width: 20rem;
  height: 20rem;
  bottom: -5rem;
  right: -5rem;
  z-index: -10;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.1)), to(rgb(255, 255, 255)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgb(255, 255, 255));
  opacity: 0.2;
}

.skip-page {
  position: fixed;
  top: 2rem;
  z-index: 100;
  line-height: 1;
  right: 2rem;
}
.skip-page a {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
}
.skip-page a:hover, .skip-page a:focus {
  color: var(--warning-500);
}

/* :: Image Gallery */
.masonry-content-wrapper {
  overflow: hidden;
}
.masonry-content-wrapper > * {
  width: calc(50% - 1rem);
  margin: 0.5rem;
}

.single-gallery-item {
  position: relative;
  z-index: 1;
  border-radius: 0.4rem;
  -webkit-transition-duration: 100ms;
          transition-duration: 100ms;
}
.single-gallery-item img {
  border-radius: 0.4rem;
}
.single-gallery-item .fav-icon {
  text-align: center;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  background-color: var(--white);
  color: var(--danger-500);
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 10;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
}
.single-gallery-item .fav-icon i {
  line-height: 32px;
}
.single-gallery-item .fav-icon.active {
  background-color: var(--danger-500);
  color: var(--white);
}
.single-gallery-item:hover .fav-icon, .single-gallery-item:focus .fav-icon {
  visibility: visible;
  opacity: 1;
}

.vbox-container {
  overflow-y: hidden !important;
}

.vbox-child {
  border-radius: 6px;
}
.vbox-child img {
  border-radius: 6px;
  max-height: 90vh;
}

.vbox-bottom {
  bottom: 8px !important;
}

.vbox-share {
  font-size: 18px;
  border-radius: 8px;
}

.vbox-title {
  color: var(--white);
}

.vbox-close {
  font-size: 20px;
}

/* :: List Group */
.list-group {
  border-radius: 0.4rem;
}

.list-group-item {
  position: relative;
  z-index: 1;
  border-color: var(--primary-200);
  font-size: 14px;
  color: var(--primary-950);
  padding: 0.625rem 1rem;
}
.list-group-item.active {
  background-color: var(--white);
  border-color: var(--primary-200) !important;
  color: var(--primary-950);
  font-weight: 500;
}
.list-group-item.active::after {
  position: absolute;
  width: 3px;
  height: 74%;
  content: "";
  background-color: var(--primary-950);
  top: 13%;
  left: 0;
  z-index: -10;
}
.list-group-item.disabled {
  background-color: var(--white);
  border-color: var(--primary-200) !important;
  color: var(--primary-200);
  font-weight: 500;
}
.list-group-item.disabled::after {
  position: absolute;
  width: 3px;
  height: 74%;
  content: "";
  background-color: var(--primary-200);
  top: 13%;
  left: 0;
  z-index: -10;
}

.list-group-flush .list-group-item {
  background-color: transparent !important;
}
.list-group-flush .list-group-item.disabled {
  border-color: var(--primary-200) !important;
  color: var(--primary-200);
  font-weight: 500;
}
.list-group-flush .list-group-item.disabled::after {
  position: absolute;
  width: 3px;
  height: 74%;
  content: "";
  background-color: var(--primary-200);
  top: 13%;
  left: 0;
  z-index: -10;
}

/* :: Loader */
.circle-loader {
  position: relative;
  z-index: 1;
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
}
.circle-loader .circle-big {
  position: relative;
  z-index: 1;
  width: 2rem;
  height: 2rem;
  border: 2px solid rgba(255, 255, 255, 0.85);
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-radius: 50%;
  -webkit-animation: circlebig 1s linear 0s infinite;
          animation: circlebig 1s linear 0s infinite;
}

.dot-loader {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dot-loader > div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--white);
  margin: 0 0.375rem;
}
.dot-loader .dot1 {
  -webkit-animation: dotloader 1s linear 0s infinite;
          animation: dotloader 1s linear 0s infinite;
}
.dot-loader .dot2 {
  -webkit-animation: dotloader 0.75s linear 0s infinite;
          animation: dotloader 0.75s linear 0s infinite;
}
.dot-loader .dot3 {
  -webkit-animation: dotloader 1s linear 0s infinite;
          animation: dotloader 1s linear 0s infinite;
}

.circle-spinner {
  position: relative;
  z-index: 1;
  width: 2.4rem;
  height: 2.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.circle-spinner .circle {
  position: relative;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--primary-950);
}
.circle-spinner .circle::after {
  position: absolute;
  width: 0%;
  height: 0%;
  background-color: var(--primary-950);
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -5;
  opacity: 0.7;
  -webkit-animation: circleloader2 1.5s linear 0s infinite;
          animation: circleloader2 1.5s linear 0s infinite;
}
.circle-spinner.circle-spinner-success .circle {
  background-color: var(--success-500);
}
.circle-spinner.circle-spinner-success .circle::after {
  background-color: var(--success-500);
}
.circle-spinner.circle-spinner-danger .circle {
  background-color: var(--danger-500);
}
.circle-spinner.circle-spinner-danger .circle::after {
  background-color: var(--danger-500);
}
.circle-spinner.circle-spinner-warning .circle {
  background-color: var(--warning-500);
}
.circle-spinner.circle-spinner-warning .circle::after {
  background-color: var(--warning-500);
}
.circle-spinner.circle-spinner-info .circle {
  background-color: var(--info-500);
}
.circle-spinner.circle-spinner-info .circle::after {
  background-color: var(--info-500);
}
.circle-spinner.circle-spinner-light .circle {
  background-color: var(--primary-100);
}
.circle-spinner.circle-spinner-light .circle::after {
  background-color: var(--primary-100);
}
.circle-spinner.circle-spinner-dark .circle {
  background-color: var(--dark);
}
.circle-spinner.circle-spinner-dark .circle::after {
  background-color: var(--dark);
}

/* :: Modal */
.modal-content {
  border: 0;
  border-radius: 0.75rem;
}

.modal.fade.bottom-align-modal {
  overflow: hidden;
}

.modal-dialog-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: calc(100% - 1rem);
}

.modal.fade .modal-dialog.modal-dialog-end {
  -webkit-transform: translate(0, 50px);
          transform: translate(0, 50px);
}

.modal.show .modal-dialog.modal-dialog-end {
  -webkit-transform: none;
          transform: none;
}

/* :: Notification */
.notification-area {
  position: relative;
  z-index: 1;
}
.notification-area .alert {
  margin-bottom: 0.5rem;
}
.notification-area a:last-child .alert {
  margin-bottom: 0;
}
.notification-area .unread {
  background-color: var(--white) !important;
}

/* :: Pagination */
.page-link {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 14px;
}

.pagination.pagination-one .page-link {
  border: 1px solid var(--primary-200);
  border-left: 0;
  border-right: 0;
}
.pagination.pagination-one .page-link:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.pagination.pagination-one .page-item:first-child .page-link {
  border-left: 1px solid var(--primary-200);
}
.pagination.pagination-one .page-item:last-child .page-link {
  border-right: 1px solid var(--primary-200);
}
.pagination.pagination-one .page-item.active .page-link {
  position: relative;
  z-index: 1;
  color: var(--white);
  background-color: transparent;
}
.pagination.pagination-one .page-item.active .page-link::after {
  position: absolute;
  width: 22px;
  height: 22px;
  content: "";
  background-color: var(--primary-950);
  z-index: -2;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.pagination.pagination-two .page-link {
  border: 0;
}
.pagination.pagination-two .page-link:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.pagination.pagination-two .page-item.active .page-link {
  position: relative;
  z-index: 1;
  color: var(--white);
  background-color: transparent;
}
.pagination.pagination-two .page-item.active .page-link::after {
  position: absolute;
  width: 22px;
  height: 22px;
  content: "";
  background-color: var(--primary-950);
  z-index: -2;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.pagination.pagination-three .page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.pagination.pagination-three .page-link {
  border: 0;
  background-color: var(--primary-950);
  border-radius: 0;
  color: var(--white);
}
.pagination.pagination-three .page-link:hover, .pagination.pagination-three .page-link:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: var(--primary-950);
  border: 0;
}
.pagination.pagination-three .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.pagination.pagination-three .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

/* :: Partner */
.partner-logo-slide-wrapper,
.partner-logo-slide-wrapper-2 {
  position: relative;
  z-index: 1;
}
.partner-logo-slide-wrapper .tns-nav,
.partner-logo-slide-wrapper-2 .tns-nav {
  margin-top: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.partner-logo-slide-wrapper .tns-nav button,
.partner-logo-slide-wrapper-2 .tns-nav button {
  border: 0;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  width: 1.5rem;
  height: 0.25rem;
  border-radius: 50px;
  background-color: var(--primary-100);
  margin: 0 0.25rem;
}
.partner-logo-slide-wrapper .tns-nav button.tns-nav-active,
.partner-logo-slide-wrapper-2 .tns-nav button.tns-nav-active {
  background-color: var(--primary-950);
  width: 1.75rem;
}

.partner-logo-slide-wrapper-2 .tns-nav button.tns-nav-active {
  background-color: var(--warning-500);
}

/* :: Price Table */
.price-table-one {
  position: relative;
  z-index: 1;
}
.price-table-one .nav-tabs .nav-link {
  margin-bottom: 0;
  border: 1px solid var(--primary-200);
  background-color: var(--white);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 0.5rem;
  color: var(--primary-950);
  font-size: 2rem;
}
.price-table-one .nav-tabs .nav-link.active {
  background-color: var(--primary-950);
  color: var(--white);
  border-color: var(--primary-950);
}
.price-table-one .single-price-content {
  position: relative;
  z-index: 1;
  background-color: var(--primary-950);
  border-radius: 1rem;
  overflow: hidden;
  max-width: 22rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem;
}
.price-table-one .single-price-content::before {
  width: 10rem;
  height: 10rem;
  position: absolute;
  background-color: var(--white);
  border-radius: 50%;
  content: "";
  top: -3rem;
  right: -5rem;
  opacity: 0.1;
  z-index: -1;
}
.price-table-one .single-price-content .price h2 {
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 700;
  color: var(--white);
}
.price-table-one .single-price-content .pricing-desc {
  margin-top: 2rem;
  margin-bottom: 2.25rem;
}
.price-table-one .single-price-content .pricing-desc ul li {
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
}
.price-table-one .single-price-content .pricing-desc ul li.times {
  opacity: 0.5;
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link {
  color: var(--danger-500);
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link.active {
  background-color: var(--danger-500);
  border-color: var(--danger-500);
  color: var(--white);
}
.price-table-one .nav-tabs .nav-item:nth-child(3) .nav-link {
  color: var(--success-500);
}
.price-table-one .nav-tabs .nav-item:nth-child(3) .nav-link.active {
  background-color: var(--success-500);
  border-color: var(--success-500);
  color: var(--white);
}
.price-table-one .tab-content {
  border: 0;
}
.price-table-one .tab-content .tab-pane:nth-child(2) .single-price-content {
  background-color: var(--danger-500);
}
.price-table-one .tab-content .tab-pane:nth-child(3) .single-price-content {
  background-color: var(--success-500);
}

.price-table-two {
  position: relative;
  z-index: 1;
}
.price-table-two .single-price-table {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
          flex: 1 0 0;
  text-align: center;
  padding: 1rem 0.5rem;
  border-radius: 0.4rem;
  background-color: var(--primary-100);
}
.price-table-two .single-price-table:nth-child(2) {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.price-table-two .single-price-table .text h6 {
  display: block;
  margin-bottom: 0;
}
.price-table-two .single-price-table .price {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
  z-index: 1;
}
.price-table-two .single-price-table .price::before, .price-table-two .single-price-table .price::after {
  position: absolute;
  width: 50px;
  height: 3px;
  border-radius: 6px;
  z-index: 1;
  top: -1rem;
  content: "";
  left: 50%;
  margin-left: -25px;
  background-color: var(--white);
  opacity: 0.3;
}
.price-table-two .single-price-table .price::after {
  bottom: -1rem;
  top: auto;
}
.price-table-two .single-price-table .price h3 {
  display: block;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
}
.price-table-two .single-price-table .price span {
  font-size: 13px;
  display: block;
}
.price-table-two .single-price-table .form-check {
  padding-left: 50%;
  margin-left: -0.75rem;
}
.price-table-two .single-price-table .form-check .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0;
}
.price-table-two .single-price-table.active {
  background-color: var(--primary-950);
  border-color: transparent !important;
}
.price-table-two .single-price-table.active .text h6 {
  color: var(--white);
}
.price-table-two .single-price-table.active .price h3 {
  color: var(--white);
}
.price-table-two .single-price-table.active .price span {
  color: var(--primary-200);
}
.price-table-two .single-price-table.active .badge {
  background-color: var(--danger-500) !important;
}

.comparison-table-one .table > :not(caption) > * > *,
.comparison-table-two .table > :not(caption) > * > * {
  padding: 0.5rem 0.75rem;
}

.comparison-table-two .table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--primary-200);
}
.comparison-table-two .table thead tr th:nth-child(2) {
  text-align: center;
}
.comparison-table-two .table thead tr th:last-child {
  background-color: var(--primary-950);
  color: var(--white);
  border-bottom-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 0.25rem 0.25rem 0 0;
  text-align: center;
}
.comparison-table-two .table tbody tr td:nth-child(2) {
  text-align: center;
}
.comparison-table-two .table tbody tr td:last-child {
  background-color: var(--primary-950);
  color: var(--white);
  border-bottom-color: rgba(255, 255, 255, 0.15) !important;
  text-align: center;
}
.comparison-table-two .table tbody tr:last-child td {
  border-bottom-color: transparent !important;
}
.comparison-table-two .table tbody tr:last-child td:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}
.comparison-table-two .table tbody tr:last-child th {
  border-bottom-color: transparent !important;
}

/* :: Progress */
.skill-progress-bar {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
}
.skill-progress-bar:last-child {
  margin-bottom: 0;
}
.skill-progress-bar .skill-icon {
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.5rem;
          flex: 0 0 2.5rem;
  max-width: 2.5rem;
  margin-right: 1rem;
  border-radius: 0.4rem;
  border: 1px solid var(--primary-200);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.skill-progress-bar .skill-data {
  width: 100%;
}
.skill-progress-bar .skill-name p {
  color: var(--primary-950);
  font-weight: 500;
  font-size: 14px;
}
.skill-progress-bar .skill-name small {
  font-weight: 500;
}

.progress-info span {
  display: inline-block;
  font-size: 12px;
  margin-top: 0.25rem;
}

.single-task-progress {
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
}
.single-task-progress:last-child {
  margin-bottom: 0;
}
.single-task-progress .who-working a {
  display: inline-block;
  margin-left: -0.375rem;
}
.single-task-progress .who-working a:first-child {
  margin-left: 0;
}
.single-task-progress .who-working a img {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--primary-200);
  border-radius: 50%;
}

/* :: Rating */
.rating-card-one {
  position: relative;
  z-index: 1;
}
.rating-card-one a {
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-size: 1.25rem;
  color: var(--warning-500);
}
.rating-card-one a:last-child {
  margin-right: 0;
}
.rating-card-one span {
  font-size: 14px;
  font-weight: 500;
}
.rating-card-one > div {
  background-color: var(--white);
}

.rating-card-two {
  position: relative;
  z-index: 1;
}
.rating-card-two a {
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-size: 1.25rem;
  color: var(--warning-500);
}
.rating-card-two a:last-child {
  margin-right: 0;
}
.rating-card-two span {
  font-size: 12px;
  font-weight: 500;
}

.rating-card-three .stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.rating-card-three .stars .star-icon {
  stroke: var(--warning-500);
  stroke-width: 4px;
  fill: transparent;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.rating-card-three .stars .stars-star {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  cursor: pointer;
  margin: 0 0.25rem;
}
.rating-card-three .stars .stars-checkbox {
  position: absolute;
  top: -9999rem;
  opacity: 0 !important;
  visibility: hidden;
  width: 0;
  height: 0;
}
.rating-card-three .stars-star:hover > .star-icon {
  fill: var(--warning-500);
}
.rating-card-three .stars-star:hover ~ .stars-star > .star-icon {
  fill: var(--warning-500);
}
.rating-card-three .stars-checkbox:checked + .stars-star > .star-icon {
  fill: var(--warning-500);
}
.rating-card-three .stars-checkbox:checked ~ .stars-star > .star-icon {
  fill: var(--warning-500);
}

.rating-detail span:first-child {
  margin-right: 0.25rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.5rem;
          flex: 0 0 2.5rem;
  width: 2.5rem;
}
.rating-detail span:last-child {
  margin-left: auto;
  color: var(--text);
}
.rating-detail .progress-bar-wrapper {
  width: 70%;
  border: 1px solid var(--primary-100);
  padding: 3px 6px;
  border-radius: 2rem;
}
.rating-detail .progress-bar-wrapper .progress {
  height: 0.25rem;
}

/* :: Scrollspy */
.scrollspy-indicatiors .nav-link {
  padding: 0.25rem 0.625rem;
  background-color: var(--primary-100);
  color: var(--primary-950);
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  font-size: 14px;
}
.scrollspy-indicatiors .nav-link.active {
  background-color: var(--primary-950);
  color: var(--white);
}
.scrollspy-indicatiors ul li:last-child .nav-link {
  margin-right: 0;
}

.data-scrollspy {
  position: relative;
  z-index: 1;
  height: 200px;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-right: 0.5rem;
}

.vertical-scrollspy {
  position: relative;
  z-index: 1;
}
.vertical-scrollspy .scrollspy-indicatiors {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 95px;
          flex: 0 0 95px;
  width: 95px;
  min-width: 95px;
}
.vertical-scrollspy .scrollspy-indicatiors .nav-link {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.vertical-scrollspy .scrollspy-indicatiors ul li:last-child .nav-link {
  margin-bottom: 0;
  margin-right: 0.5rem;
}

/* :: Table */
.table {
  font-size: 14px;
}
.table tr td,
.table tr th {
  font-weight: 400;
}
.table thead tr td,
.table thead tr th {
  font-weight: 500;
}

.dataTable-top {
  padding: 0;
}
.dataTable-top .dataTable-selector {
  width: 3.75rem;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  border: 1px solid var(--primary-200);
  padding: 0.125rem 0.5rem;
  height: 2rem;
  font-size: 12px;
  color: var(--primary-950);
  background-color: var(--white);
  border-radius: 0.25rem;
}
.dataTable-top .dataTable-selector:focus {
  outline: none !important;
}
.dataTable-top .dataTable-input {
  width: 7rem;
  height: 2rem;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  border: 1px solid var(--primary-200);
  font-size: 12px;
  color: var(--primary-950);
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}
.dataTable-top .dataTable-input:focus-visible {
  border: 0;
}

.dataTable-container {
  border-bottom: 0 !important;
  margin: 1rem 0;
}
.dataTable-container .dataTable-table {
  overflow-x: scroll;
}
.dataTable-container .dataTable-table thead > tr > th {
  border: 1px solid var(--primary-100);
  font-size: 12px;
  background-color: var(--primary-100);
}
.dataTable-container .dataTable-table tbody tr td,
.dataTable-container .dataTable-table tbody tr th {
  font-size: 12px;
  border: 1px solid var(--primary-100);
}

.dataTable-bottom {
  padding: 0;
}

.dataTable-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dataTable-pagination li a {
  height: auto;
  color: var(--primary-950);
  background-color: var(--primary-100);
  font-size: 14px;
  padding: 0 0.25rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
}
.dataTable-pagination li a:hover, .dataTable-pagination li a:focus {
  background-color: var(--primary-700);
  color: var(--white);
}
.dataTable-pagination li.active a {
  background-color: var(--primary-700);
  color: var(--white);
}
.dataTable-pagination li:first-child a, .dataTable-pagination li:last-child a {
  font-size: 18px;
  padding: 0 0.125rem;
}

.dataTable-info {
  margin: 0;
  font-size: 14px;
}

.dataTable-sorter::after,
.dataTable-sorter::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  opacity: 0.3;
  right: 0;
}

.dataTable-sorter::before {
  border-top: 6px solid var(--primary-950);
  bottom: 1px;
}

.dataTable-sorter::after {
  border-bottom: 6px solid var(--primary-950);
  border-top: 6px solid transparent;
  top: -3px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 1;
}

/* :: Testimonial */
.testimonial-slide-one-wrapper {
  position: relative;
  z-index: 1;
}
.testimonial-slide-one-wrapper .tns-nav {
  position: absolute;
  z-index: 10;
  right: 1.5rem;
  bottom: 1.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.testimonial-slide-one-wrapper .tns-nav button {
  border: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary-200);
  margin: 0 0.25rem;
  border-radius: 50%;
}
.testimonial-slide-one-wrapper .tns-nav button.tns-nav-active {
  background-color: var(--primary-950);
}

.testimonial-slide-two-wrapper {
  position: relative;
  z-index: 1;
}
.testimonial-slide-two-wrapper .tns-controls button[data-controls=prev],
.testimonial-slide-two-wrapper .tns-controls button[data-controls=next] {
  border: 0;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  top: 50%;
  left: 5%;
  z-index: 10;
  background-color: var(--white);
  text-align: center;
  color: var(--primary-950);
  margin-top: -1rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}
.testimonial-slide-two-wrapper .tns-controls button[data-controls=prev] i,
.testimonial-slide-two-wrapper .tns-controls button[data-controls=next] i {
  line-height: 34px;
}
.testimonial-slide-two-wrapper .tns-controls button[data-controls=prev]:hover, .testimonial-slide-two-wrapper .tns-controls button[data-controls=prev]:focus,
.testimonial-slide-two-wrapper .tns-controls button[data-controls=next]:hover,
.testimonial-slide-two-wrapper .tns-controls button[data-controls=next]:focus {
  background-color: var(--warning-500);
}
.testimonial-slide-two-wrapper .tns-controls button[data-controls=next] {
  left: auto;
  right: 5%;
}

.testimonial-slide-three-wrapper {
  position: relative;
  z-index: 1;
}
.testimonial-slide-three-wrapper .tns-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1rem;
}
.testimonial-slide-three-wrapper .tns-nav button {
  border: 0;
  width: 1rem;
  height: 0.25rem;
  background-color: var(--primary-200);
  margin: 0 0.25rem;
  border-radius: 0.25rem;
}
.testimonial-slide-three-wrapper .tns-nav button.tns-nav-active {
  background-color: var(--primary-950);
}

.testimonial-style1 .single-testimonial-slide {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.testimonial-style1 .single-testimonial-slide .image-wrapper {
  margin-right: 1.25rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 80px;
          flex: 0 0 80px;
  width: 80px;
  max-width: 80px;
  border-radius: 1rem;
}
.testimonial-style1 .single-testimonial-slide .image-wrapper img {
  border-radius: 1rem;
}
.testimonial-style1 .single-testimonial-slide i {
  position: absolute;
  top: -10px;
  left: 60px;
  z-index: 1;
  font-size: 24px;
}

.testimonial-style2 {
  position: relative;
  z-index: 1;
}
.testimonial-style2 .single-testimonial-slide {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  position: relative;
  z-index: 1;
  border: 1px solid transparent;
  padding: 1rem;
  border-radius: 0.4rem;
  margin: 1rem 0;
}
.testimonial-style2 .single-testimonial-slide .image-wrapper {
  position: relative;
  z-index: 1;
  margin: 0 auto 1.5rem;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.testimonial-style2 .single-testimonial-slide .image-wrapper::after {
  width: 100%;
  height: 100%;
  border: 2px solid var(--primary-100);
  position: absolute;
  content: "";
  top: -5px;
  left: 5px;
  border-radius: 50%;
  z-index: -1;
}
.testimonial-style2 .single-testimonial-slide .image-wrapper > i {
  position: absolute;
  bottom: -5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background-color: var(--warning-500);
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: var(--white);
  font-size: 1rem;
}
.testimonial-style2 .single-testimonial-slide .image-wrapper img {
  border-radius: 50%;
}
.testimonial-style2 .single-testimonial-slide .text-content {
  text-align: center;
}

.testimonial-style3 {
  position: relative;
  z-index: 1;
}
.testimonial-style3 .single-testimonial-slide .text-content p {
  font-weight: 500;
}

/* :: Timeline */
.timeline-card {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
  border-left: 0.5rem solid var(--primary-500) !important;
}
.timeline-card::after {
  position: absolute;
  content: "";
  bottom: 1.5rem;
  right: 1.5rem;
  background-color: var(--primary-950);
  width: 16px;
  height: 16px;
  opacity: 0.05;
  border-radius: 50%;
}
.timeline-card:last-child {
  margin-bottom: 0;
}
.timeline-card p {
  font-size: 13px;
}
.timeline-card .timeline-text .badge {
  background-color: var(--primary-950);
}
.timeline-card .timeline-icon svg,
.timeline-card .timeline-icon i {
  color: var(--primary-950);
}
.timeline-card .timeline-tags span {
  display: inline-block;
  margin: 0.125rem;
}
.timeline-card.bg-success {
  border-left-color: var(--success-500) !important;
  background-color: var(--white) !important;
}
.timeline-card.bg-success .timeline-icon svg,
.timeline-card.bg-success .timeline-icon i {
  color: var(--success-500);
}
.timeline-card.bg-success .timeline-text .badge {
  background-color: var(--success-500);
}
.timeline-card.bg-danger {
  border-left-color: var(--danger-500) !important;
  background-color: var(--white) !important;
}
.timeline-card.bg-danger .timeline-icon svg,
.timeline-card.bg-danger .timeline-icon i {
  color: var(--danger-500);
}
.timeline-card.bg-danger .timeline-text .badge {
  background-color: var(--danger-500);
}
.timeline-card.bg-warning {
  border-left-color: var(--warning-500) !important;
  background-color: var(--white) !important;
}
.timeline-card.bg-warning .timeline-icon svg,
.timeline-card.bg-warning .timeline-icon i {
  color: var(--warning-500);
}
.timeline-card.bg-warning .timeline-text .badge {
  background-color: var(--warning-500);
}
.timeline-card.bg-info {
  border-left-color: var(--info-500) !important;
  background-color: var(--white) !important;
}
.timeline-card.bg-info .timeline-icon svg,
.timeline-card.bg-info .timeline-icon i {
  color: var(--info-500);
}
.timeline-card.bg-info .timeline-text .badge {
  background-color: var(--info-500);
}
.timeline-card.bg-dark {
  border-left-color: var(--dark) !important;
  background-color: var(--white) !important;
}
.timeline-card.bg-dark .timeline-icon svg,
.timeline-card.bg-dark .timeline-icon i {
  color: var(--dark);
}
.timeline-card.bg-dark .timeline-text .badge {
  background-color: var(--dark);
}
.timeline-card.bg-secondary {
  border-left-color: var(--text) !important;
  background-color: var(--white) !important;
}
.timeline-card.bg-secondary .timeline-icon svg,
.timeline-card.bg-secondary .timeline-icon i {
  color: var(--text);
}
.timeline-card.bg-secondary .timeline-text .badge {
  background-color: var(--text);
}

/* :: Toast */
.toast-header {
  color: var(--primary-950);
  padding: 0.5rem 0.75rem;
}

.custom-toast-1 {
  position: relative;
  z-index: 1;
  background-color: var(--white);
  border-radius: 0.4rem;
}
.custom-toast-1 .toast-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem;
}
.custom-toast-1 .toast-body svg {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30px;
          flex: 0 0 30px;
  width: 30px;
  max-width: 30px;
}
.custom-toast-1 .toast-body .toast-text p {
  color: var(--primary-950);
  font-weight: 500;
}
.custom-toast-1 .btn-close {
  top: 0.625rem;
  right: 0.625rem;
  z-index: 10;
}

.toast-primary {
  background-color: var(--primary-500) !important;
  color: var(--white);
}

.toast-success {
  background-color: var(--success-500) !important;
  color: var(--white);
}

.toast-danger {
  background-color: var(--danger-500) !important;
  color: var(--white);
}

.toast-warning {
  background-color: var(--warning-500) !important;
  color: var(--white);
}

.toast-info {
  background-color: var(--info-500) !important;
  color: var(--white);
}

.toast-dark {
  background-color: var(--dark) !important;
  color: var(--white);
}

.toast-autohide-animation {
  width: 0%;
  height: 4px;
  position: absolute;
  z-index: 10;
  content: "";
  bottom: 0;
  left: 0;
  background-color: var(--primary-100);
  -webkit-animation: toast-animation linear 0s;
          animation: toast-animation linear 0s;
  border-radius: 0 0 0 2rem;
}

/* Tab */
.tab-content {
  border: 1px solid var(--primary-200);
}

.nav-tabs .nav-link {
  font-size: 14px;
  border: 0;
}

.nav,
.tab-content {
  border: 0;
}

.standard-tab .nav {
  border: 1px solid var(--primary-200);
}
.standard-tab .tab-content {
  border: 1px solid var(--primary-200);
}
.standard-tab .nav-item {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.standard-tab .btn {
  padding: 6px 8px;
  width: 100%;
  border: 0 !important;
}
.standard-tab .btn.active {
  background-color: var(--primary-950);
  color: var(--white);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.minimal-tab .btn {
  padding: 0 0.625rem 0.5rem;
  width: 100%;
  border-radius: 0;
  line-height: 1;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 3px solid transparent;
  margin-bottom: -2px;
}
.minimal-tab .btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.minimal-tab .btn.active {
  border-bottom-color: var(--primary-950);
  color: var(--primary-950);
}

.colorful-tab .nav {
  border-radius: 20rem;
  background-color: rgba(255, 255, 255, 0.125);
}
.colorful-tab .nav .nav-item {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.colorful-tab .btn {
  border-radius: 5rem;
  width: 100%;
  background-color: transparent;
  border: 0;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
}
.colorful-tab .btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.colorful-tab .btn.active {
  background-color: rgba(255, 255, 255, 0.25);
  color: var(--white);
}
.colorful-tab .tab-content {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  border: 0;
}

/* :: Blog */
.card-blog-img {
  width: 50%;
  max-width: 50%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  height: 173px;
  background-position: center center;
  background-size: cover;
  border-radius: 0.5rem 0 0 0.5rem;
}

.card-blog-content {
  padding: 1.5rem;
  width: 50%;
  max-width: 50%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}

.blog-description p {
  font-size: 1rem;
}
.blog-description p:last-child {
  margin-bottom: 0;
}

.blog-title {
  font-weight: 500;
  overflow: hidden;
  height: 48px;
}
.blog-title:hover, .blog-title:focus {
  color: var(--primary-500) !important;
}

.single-user-review {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
}
.single-user-review:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.single-user-review .user-thumbnail {
  margin-top: 0.5rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40px;
          flex: 0 0 40px;
  width: 40px;
  max-width: 40px;
  margin-right: 0.5rem;
}
.single-user-review .user-thumbnail img {
  border-radius: 50%;
}
.single-user-review .name-date {
  display: block;
  font-size: 12px;
}

.post-bookmark {
  background-color: rgba(255, 255, 255, 0.2);
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 50%;
  color: var(--white);
}
.post-bookmark:hover, .post-bookmark:focus {
  background-color: var(--danger-500);
}

/* :: Cart */
.cart-table {
  position: relative;
  z-index: 1;
}
.cart-table table {
  max-width: 100%;
}
.cart-table table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--primary-200);
}
.cart-table table .remove-product {
  color: var(--primary-950);
  width: 28px;
  height: 28px;
  background-color: var(--primary-100);
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}
.cart-table table .remove-product i {
  line-height: 28px;
}
.cart-table table .remove-product:hover, .cart-table table .remove-product:focus {
  color: var(--white);
  background-color: var(--primary-950);
}
.cart-table .table tbody td,
.cart-table .table tbody th {
  vertical-align: middle;
  color: var(--text);
  font-size: 12px;
  padding: 0.75rem 0.25rem;
  border: 0;
}
.cart-table .table tbody td h6,
.cart-table .table tbody th h6 {
  font-size: 14px;
}
.cart-table .table tbody td thead th,
.cart-table .table tbody th thead th {
  padding: 0.75rem 0.25rem;
}
.cart-table img {
  max-height: 2.75rem;
}
.cart-table .qty-text {
  border: 2px solid var(--primary-200);
  width: 3rem;
  text-align: center;
  height: 2rem;
  border-radius: 0.25rem;
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  font-weight: 500;
}

/* :: Language */
.language-lists {
  position: relative;
  z-index: 1;
}
.language-lists .form-check {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
.language-lists .form-check-label {
  color: var(--text);
}
.language-lists li {
  padding: 0.375rem 0.75rem;
  border: 1px solid var(--primary-200);
  border-radius: 0.375rem;
  margin-bottom: 0.75rem;
}
.language-lists li:last-child {
  margin-bottom: 0;
}

/* :: Product */
.product-details-card {
  position: relative;
  z-index: 1;
}
.product-details-card .product-badge {
  top: 2.5rem;
  left: 2.5rem;
  z-index: 100;
}
.product-details-card .product-gallery > a {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.single-product-card {
  position: relative;
  z-index: 1;
}
.single-product-card .product-thumbnail {
  position: relative;
  z-index: 1;
}
.single-product-card .product-thumbnail img {
  border-radius: 0.375rem;
}
.single-product-card .product-thumbnail .badge {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 10;
}
.single-product-card .product-title {
  font-size: 1rem;
  color: var(--primary-950);
  margin-top: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.single-product-card .sale-price {
  font-size: 1rem;
  color: var(--primary-950);
  font-weight: 500;
}
.single-product-card .sale-price span {
  font-size: 1rem;
  margin-left: 0.25rem;
  text-decoration: line-through;
  color: var(--danger-500);
}

.product-list-wrap .single-product-card .product-thumbnail img {
  max-height: 7rem;
}

.shop-pagination small {
  border-left: 3px solid var(--primary-950);
  padding-left: 0.5rem;
  line-height: 1;
}

.product-gallery-wrapper {
  position: relative;
}
.product-gallery-wrapper .tns-nav {
  position: absolute;
  width: 100%;
  z-index: 10;
  bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.product-gallery-wrapper .tns-nav button {
  border: 0 !important;
  margin: 0 4px;
  width: 24px;
  height: 4px;
  border-radius: 50px;
  background-color: var(--white);
}
.product-gallery-wrapper .tns-nav button.tns-nav-active {
  background-color: var(--danger-500);
}

/* :: Service */
.service-card {
  position: relative;
  z-index: 1;
}
.service-card .card-body {
  padding: 2rem;
}
.service-card .service-text {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
          flex: 0 0 60%;
  max-width: 60%;
  width: 60%;
}
.service-card .service-text h1,
.service-card .service-text h2,
.service-card .service-text h3,
.service-card .service-text h4,
.service-card .service-text h5,
.service-card .service-text h6 {
  color: var(--white);
}
.service-card .service-text p {
  color: rgba(255, 255, 255, 0.75);
}
.service-card .service-img {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
  max-width: 40%;
  width: 40%;
  text-align: right;
  padding-left: 1.5rem;
}
.service-card .service-img img {
  max-width: 100%;
}

/* :: Team */
.team-member-card {
  position: relative;
  z-index: 1;
  text-align: center !important;
  background-color: var(--white) !important;
  overflow: hidden;
}
.team-member-card .team-member-img {
  border-radius: 50%;
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
  max-width: 8rem;
  margin-left: auto;
  margin-right: auto;
}
.team-member-card .team-member-img::after {
  position: absolute;
  width: 90%;
  height: 90%;
  content: "";
  top: 5%;
  right: 5%;
  z-index: 10;
  border: 1px solid var(--white);
  border-radius: 50%;
}
.team-member-card .team-member-img img {
  border-radius: 50%;
}
.team-member-card .contact-info {
  padding: 0.5rem 1rem;
  border-radius: 0 0 6px 6px;
}
.team-member-card .contact-info p {
  color: var(--white);
}

/* :: User Profile */
.user-info-card {
  position: relative;
  z-index: 1;
}
.user-info-card .user-profile {
  position: relative;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.user-info-card .user-profile i {
  position: absolute;
  top: 11px;
  right: 8px;
  font-size: 14px;
  color: var(--white);
  z-index: 1;
}
.user-info-card .user-profile img {
  border-radius: 50%;
}
.user-info-card .user-profile form .form-control {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  top: 6px;
  border: 0;
  border-radius: 50%;
  padding: 0;
  line-height: 30px;
  text-indent: -100000000000000000rem;
  background-color: var(--primary-950);
}

/* :: Demo */
.demo-header-wrapper {
  background-color: var(--white);
  border-bottom: 1px solid var(--primary-200);
}

#previewPage .vbox-close {
  background-color: var(--danger-500);
  top: 10px;
  right: 10px;
  padding: 8px 8px;
  opacity: 1;
  border-radius: 0.375rem;
}

@media only screen and (min-width:1400px) {
  .preview-iframe-wrapper .container.demo-container {
    max-width: 1320px;
  }
}
@media only screen and (min-width:1200px) and (max-width:1399px) {
  .preview-iframe-wrapper .container.demo-container {
    max-width: 1140px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .preview-iframe-wrapper .container.demo-container {
    max-width: 960px;
  }
}
.preview-iframe-wrapper .preview-hero-area {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 75px;
  overflow: hidden;
  background-color: var(--white);
}
.preview-iframe-wrapper .preview-hero-area .big-shadow-text {
  position: absolute;
  bottom: -8rem;
  font-size: 25rem;
  left: -4rem;
  line-height: 1;
  opacity: 0.08;
  font-weight: 700;
  z-index: -99;
}
@media only screen and (min-width:1200px) and (max-width:1399px) {
  .preview-iframe-wrapper .preview-hero-area .big-shadow-text {
    font-size: 20rem;
    bottom: -6rem;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .preview-iframe-wrapper .preview-hero-area .big-shadow-text {
    font-size: 18rem;
    bottom: -6rem;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .preview-iframe-wrapper .preview-hero-area .big-shadow-text {
    font-size: 16rem;
    bottom: -5rem;
  }
}
@media only screen and (max-width:767px) {
  .preview-iframe-wrapper .preview-hero-area .big-shadow-text {
    font-size: 10rem;
    bottom: -3rem;
    left: -2rem;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .preview-iframe-wrapper .preview-hero-area .big-shadow-text {
    font-size: 13rem;
    bottom: -4rem;
    left: -2rem;
  }
}
.preview-iframe-wrapper .preview-hero-area .version-number {
  background-color: var(--primary-100);
  font-size: 14px;
  color: var(--text);
}
.preview-iframe-wrapper .preview-hero-area .demo-title {
  font-size: 2rem;
  font-weight: 600;
}
@media only screen and (min-width:1200px) and (max-width:1399px) {
  .preview-iframe-wrapper .preview-hero-area .demo-title {
    font-size: 1.625rem;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .preview-iframe-wrapper .preview-hero-area .demo-title {
    font-size: 1.375rem;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .preview-iframe-wrapper .preview-hero-area .demo-title {
    font-size: 1.75rem;
  }
}
@media only screen and (max-width:767px) {
  .preview-iframe-wrapper .preview-hero-area .demo-title {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .preview-iframe-wrapper .preview-hero-area .demo-title {
    font-size: 1.625rem;
  }
}
.preview-iframe-wrapper .preview-hero-area .demo-title span {
  color: var(--primary-950);
}
.preview-iframe-wrapper .preview-hero-area .demo-desc {
  font-weight: 500;
}
.preview-iframe-wrapper .preview-hero-area .qr-code-wrapper {
  text-align: center;
  margin-left: auto;
  display: inline-block;
  border-radius: 0.4rem;
  padding: 1.5rem;
}
.preview-iframe-wrapper .preview-hero-area .qr-code-wrapper img {
  max-width: 90%;
  margin: 0 auto 1rem;
}
.preview-iframe-wrapper .preview-hero-area iframe {
  position: relative;
  z-index: 1;
  width: 383px;
  height: 746px;
  border: 4px solid #404040;
  border-radius: 1.5rem;
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .preview-iframe-wrapper .preview-hero-area iframe {
    display: none;
  }
}
@media only screen and (max-width:767px) {
  .preview-iframe-wrapper .preview-hero-area iframe {
    display: none;
  }
}
.preview-iframe-wrapper .section-heading {
  padding-bottom: 75px;
}
.preview-iframe-wrapper .features-area {
  position: relative;
  z-index: 1;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: var(--white);
}
.preview-iframe-wrapper .features-area h2 {
  font-weight: 500;
}
.preview-iframe-wrapper .features-area p {
  font-size: 1.125rem;
}
.preview-iframe-wrapper .features-area .card img {
  max-height: 2rem;
}
.preview-iframe-wrapper .features-area .card.active {
  background-color: var(--primary-50);
}
.preview-iframe-wrapper .features-area .card.active h6 {
  color: #084298;
  font-weight: 600;
}

.feature-card .card {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0.5rem;
}
.feature-card .card img {
  max-width: 2.125rem;
  max-height: 2.125rem;
}
.feature-card p {
  line-height: 1.25;
  font-size: 14px;
  font-weight: 500;
}

.special-text {
  font-weight: 500;
  color: var(--primary-950);
}

.preview-footer-area {
  position: relative;
  z-index: 1;
  width: 100%;
}

.btn-others-items-preview {
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  position: fixed;
  bottom: 1rem;
  z-index: 999;
  left: 1rem;
  width: 120px;
  font-size: 14px;
  border: none;
}

.others-items-preview {
  width: 100%;
}
.others-items-preview h6 {
  font-size: 13px;
  font-weight: 600;
}

/* RTL */
[view-mode=rtl] body .rtl-mode-switching svg {
  color: var(--primary-950);
}
[view-mode=rtl] body .rtl-mode-switching .rtl-mode-text {
  display: block;
}
[view-mode=rtl] body .rtl-mode-switching .ltr-mode-text {
  display: none;
}
[view-mode=rtl] body h1,
[view-mode=rtl] body h2,
[view-mode=rtl] body h3,
[view-mode=rtl] body h4,
[view-mode=rtl] body h5,
[view-mode=rtl] body h6,
[view-mode=rtl] body p,
[view-mode=rtl] body span {
  direction: rtl;
}
[view-mode=rtl] body .elements-heading {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .elements-heading .heading-text {
  text-align: right;
}
[view-mode=rtl] body .single-setting-panel a .icon-wrapper {
  margin-right: 0;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .header-content,
[view-mode=rtl] body .setting-heading,
[view-mode=rtl] body .footer-nav ul,
[view-mode=rtl] body .range-with-value,
[view-mode=rtl] body .single-plan-check,
[view-mode=rtl] body .breadcrumb,
[view-mode=rtl] body .standard-tab .nav,
[view-mode=rtl] body .colorful-tab .nav,
[view-mode=rtl] body .minimal-tab .nav,
[view-mode=rtl] body .nav.nav-tabs {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .affan-page-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .affan-page-item .icon-wrapper {
  margin-right: 0;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .affan-page-item > i {
  margin-left: 0;
  margin-right: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
[view-mode=rtl] body .element-heading-wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .element-heading-wrapper > i {
  margin-right: 0;
  margin-left: 1rem;
}
[view-mode=rtl] body a.affan-element-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body a.affan-element-item > i {
  margin-left: 0;
  margin-right: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
[view-mode=rtl] body .back-button a {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
[view-mode=rtl] body .element-heading {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .element-heading h6 {
  text-align: right;
}
[view-mode=rtl] body .preview-iframe-wrapper .preview-hero-area .demo-desc li i {
  margin-right: 0;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .preview-iframe-wrapper .preview-hero-area .qr-code-wrapper {
  display: block;
}
[view-mode=rtl] body .badge.ms-1,
[view-mode=rtl] body .badge.ms-2 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}
[view-mode=rtl] body .sidenav-nav li a {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .sidenav-nav li a i {
  margin-right: 0;
  margin-left: 1rem;
}
[view-mode=rtl] body .nav-url .dropdown-icon {
  margin-left: 0;
  margin-right: auto;
}
[view-mode=rtl] body .nav-url .dropdown-icon i {
  margin-left: 0;
}
[view-mode=rtl] body .sidenav-nav li .night-mode-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .sidenav-nav li .night-mode-nav .form-check {
  margin-left: 0;
  margin-right: auto;
}
[view-mode=rtl] body .sidenav-nav li .night-mode-nav .form-switch {
  padding-left: 0;
}
[view-mode=rtl] body .sidenav-nav li .night-mode-nav i {
  margin-right: 0;
  margin-left: 1rem;
}
[view-mode=rtl] body .sidenav-nav li ul {
  padding-left: 0;
  padding-right: 2rem;
}
[view-mode=rtl] body .alert,
[view-mode=rtl] body .form-group,
[view-mode=rtl] body .btn.d-flex,
[view-mode=rtl] body .list-group,
[view-mode=rtl] body .input-group,
[view-mode=rtl] body .direction-rtl,
[view-mode=rtl] body .badge-avater-wrap,
[view-mode=rtl] body .badge-avater-group,
[view-mode=rtl] body .circle-btn-wrapper,
[view-mode=rtl] body .rating-card-three,
[view-mode=rtl] body .cta-card,
[view-mode=rtl] body .progress,
[view-mode=rtl] body .single-task-progress,
[view-mode=rtl] body .scrollspy-indicatiors,
[view-mode=rtl] body .vertical-scrollspy,
[view-mode=rtl] body .table,
[view-mode=rtl] body .top-products-area,
[view-mode=rtl] body .single-product-card {
  direction: rtl;
}
[view-mode=rtl] body .alert-dismissible .btn-close {
  margin-left: 0 !important;
  margin-right: auto;
}
[view-mode=rtl] body .custom-alert-one {
  padding-left: 1rem;
  padding-right: 27px;
}
[view-mode=rtl] body .custom-alert-one i {
  margin-right: 0;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .custom-alert-one::after {
  right: 12px;
  left: auto;
}
[view-mode=rtl] body .custom-alert-two i {
  margin-right: 0;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .custom-alert-three i {
  margin-right: 0;
  margin-left: 0.75rem;
}
[view-mode=rtl] body .toast {
  direction: rtl;
}
[view-mode=rtl] body .toast .toast-header .btn-close {
  margin-right: 0.375rem;
  margin-left: 0;
}
[view-mode=rtl] body .toast .toast-header strong {
  margin-left: auto !important;
  margin-right: 0.25rem !important;
}
[view-mode=rtl] body .custom-toast-1 .btn-close {
  left: 0.625rem;
  right: auto;
}
[view-mode=rtl] body .btn svg.me-2,
[view-mode=rtl] body .btn i.me-2,
[view-mode=rtl] body .form-file-button.btn.d-flex svg {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}
[view-mode=rtl] body .internet-connection-status {
  direction: rtl;
}
[view-mode=rtl] body .badge-avater-group .badge-avater {
  margin-right: 0;
  margin-left: -0.75rem;
}
[view-mode=rtl] body .offcanvas-start .btn-close {
  right: auto;
  left: 1rem;
}
[view-mode=rtl] body .offcanvas-top .btn-close,
[view-mode=rtl] body .offcanvas-bottom .btn-close {
  right: auto;
  left: 1rem;
}
[view-mode=rtl] body .form-select {
  background-position: left 0.75rem center;
}
[view-mode=rtl] body .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[view-mode=rtl] body .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
[view-mode=rtl] body .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[view-mode=rtl] body .form-check {
  direction: rtl;
}
[view-mode=rtl] body .form-check .form-check-input {
  float: right;
  margin-left: 0.5rem;
}
[view-mode=rtl] body #rangevalue {
  margin-left: 0 !important;
  margin-right: 1rem;
}
[view-mode=rtl] body .accordion-item {
  direction: rtl;
}
[view-mode=rtl] body .accordion-item .accordion-button::after {
  margin-left: 0;
  margin-right: auto;
}
[view-mode=rtl] body .accordion-style-two .accordion-item h6 i {
  margin-left: 0.5rem;
  margin-right: 0;
}
[view-mode=rtl] body .list-group-item .form-check-input {
  margin-right: 0 !important;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .list-group-item.active::after {
  left: auto;
  right: 0;
}
[view-mode=rtl] body .list-group-item.disabled::after {
  left: auto;
  right: 0;
}
[view-mode=rtl] body .breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-right: 0;
  padding-left: 0.5rem;
}
[view-mode=rtl] body .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
  padding-right: 0.5rem;
}
[view-mode=rtl] body .timeline-card {
  direction: rtl;
}
[view-mode=rtl] body .timeline-card::after {
  right: auto;
  left: 1.5rem;
}
[view-mode=rtl] body .card-badge {
  left: auto;
  right: 1.5rem;
}
[view-mode=rtl] body .card-img-wrap {
  margin-right: 0;
  margin-left: 1rem;
}
[view-mode=rtl] body .image-gallery-card .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .image-gallery-card .row .text-end {
  text-align: left !important;
}
[view-mode=rtl] body .hero-block-content {
  text-align: right;
}
[view-mode=rtl] body .rating-card-one,
[view-mode=rtl] body .rating-card-two {
  direction: rtl;
}
[view-mode=rtl] body .rating-card-one .rating a,
[view-mode=rtl] body .rating-card-two .rating a {
  margin-right: 0;
  margin-left: 4px;
}
[view-mode=rtl] body .rating-card-one span,
[view-mode=rtl] body .rating-card-two span {
  direction: ltr;
}
[view-mode=rtl] body .rating-detail span {
  margin-right: 0;
  margin-left: 4px;
}
[view-mode=rtl] body .rating-detail span:last-child {
  margin-left: 0;
  margin-right: auto;
}
[view-mode=rtl] body .testimonial-style1 .single-testimonial-slide {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .testimonial-style1 .single-testimonial-slide .image-wrapper {
  margin-right: 0;
  margin-left: 1.25rem;
}
[view-mode=rtl] body .testimonial-style1 .single-testimonial-slide i {
  left: auto;
  right: -8px;
}
[view-mode=rtl] body .testimonial-style3 .single-testimonial-slide {
  text-align: right;
}
[view-mode=rtl] body .modal-header {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .modal-header .btn-close {
  margin: -0.5rem auto -0.5rem 0 !important;
}
[view-mode=rtl] body .modal-body {
  text-align: right;
}
[view-mode=rtl] body .pagination .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[view-mode=rtl] body .pagination .page-item:first-child .page-link svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
[view-mode=rtl] body .pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
[view-mode=rtl] body .pagination .page-item:last-child .page-link svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
[view-mode=rtl] body .pagination.pagination-one .page-item:first-child .page-link {
  border-left: 0;
  border-right: 1px solid var(--primary-200);
}
[view-mode=rtl] body .pagination.pagination-one .page-item:last-child .page-link {
  border-right: 0;
  border-left: 1px solid var(--primary-200);
}
[view-mode=rtl] body .skill-progress-bar {
  direction: rtl;
}
[view-mode=rtl] body .skill-progress-bar .skill-icon {
  margin-right: 0;
  margin-left: 1rem;
}
[view-mode=rtl] body .progress-info span {
  direction: ltr;
}
[view-mode=rtl] body .list-unstyled ul {
  padding-right: 2rem;
  padding-left: 0;
}
[view-mode=rtl] body .price-table-one .single-price-content {
  direction: rtl;
}
[view-mode=rtl] body .price-table-one .single-price-content .pricing-desc ul li i {
  margin-right: 0 !important;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .price-table-two .single-price-table .form-check .form-check-input {
  float: left;
}
[view-mode=rtl] body .chat-user-list {
  direction: rtl;
}
[view-mode=rtl] body .chat-user-list .chat-user-thumbnail {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}
[view-mode=rtl] body .chat-user--info {
  direction: rtl;
}
[view-mode=rtl] body .chat-user--info .info.ms-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}
[view-mode=rtl] body .chat-user--info .user-thumbnail-name {
  margin-left: 0;
  margin-right: 0.375rem;
}
[view-mode=rtl] body .shop-pagination {
  direction: rtl;
}
[view-mode=rtl] body .shop-pagination select {
  padding-right: 0.5rem !important;
  padding-left: 1.5rem !important;
}
[view-mode=rtl] body .shop-pagination small {
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
  border-right: 3px solid var(--primary-950);
  padding-right: 0.5rem;
  margin-right: 0.25rem;
}
[view-mode=rtl] body .product-details-card .product-badge {
  left: auto;
  right: 2.5rem;
}
[view-mode=rtl] body .notification-area .alert-text {
  text-align: right;
}
[view-mode=rtl] body .modal-footer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .blog-list-card {
  direction: rtl;
}
[view-mode=rtl] body .blog-list-card .card-blog-img {
  border-radius: 0 0.5rem 0.5rem 0;
}
[view-mode=rtl] body .blog-description {
  direction: rtl;
}
[view-mode=rtl] body .blog-description span {
  margin-right: 0.5rem;
}
[view-mode=rtl] body .rating-and-review-wrapper {
  direction: rtl;
}
[view-mode=rtl] body .rating-and-review-wrapper .single-user-review .user-thumbnail {
  margin-left: 0.5rem;
  margin-right: 0;
}
[view-mode=rtl] body .service-card {
  direction: rtl;
}
[view-mode=rtl] body .service-card .service-img {
  text-align: left;
  padding-left: 0;
  padding-right: 1.5rem;
}
[view-mode=rtl] body .user-info-card {
  direction: rtl;
}
[view-mode=rtl] body .user-info-card .user-profile.me-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}
[view-mode=rtl] body .login-meta-data p a {
  margin-left: 0;
}
[view-mode=rtl] body .otp-form select {
  text-align: right;
}
[view-mode=rtl] body .demo-container.direction-rtl .text-end {
  text-align: left !important;
}
[view-mode=rtl] body .demo-container.direction-rtl .btn.btn-lg.ms-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}
[view-mode=rtl] body .nav-url ul {
  padding-left: 0;
  padding-right: 1.5rem;
}
[view-mode=rtl] body .home-page-toast {
  right: auto;
  left: 15px;
}
[view-mode=rtl] body .testimonial-slide-three-wrapper .tns-nav {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
[view-mode=rtl] body .scrollspy-indicatiors ul li:last-child .nav-link {
  margin-right: 0.5rem;
}
[view-mode=rtl] body .vertical-scrollspy .scrollspy-indicatiors .nav-link {
  margin-right: 0;
  margin-left: 0.5rem;
}
[view-mode=rtl] body .vertical-scrollspy .scrollspy-indicatiors ul li:last-child .nav-link {
  margin-right: 0;
}
[view-mode=rtl] body .tiny-slider-one-wrapper .tns-controls {
  right: auto;
  left: 1.25rem;
}
[view-mode=rtl] body .tiny-slider-one-wrapper .tns-nav {
  left: auto;
  right: 1.25rem;
}
[view-mode=rtl] body .tiny-slider-two-wrapper {
  text-align: right;
}
[view-mode=rtl] body .tiny-slider-two-wrapper .tns-nav {
  right: auto;
  left: 2rem;
}
[view-mode=rtl] body .tiny-slider-two-wrapper #totaltnsDotsCount {
  right: auto;
  left: 3.625rem;
}
[view-mode=rtl] body .tiny-slider-three-wrapper {
  text-align: right;
}
[view-mode=rtl] body .dataTable-container .dataTable-table {
  direction: rtl;
}
[view-mode=rtl] body .dataTable-sorter::after,
[view-mode=rtl] body .dataTable-sorter::before {
  right: auto;
  left: 0;
}
[view-mode=rtl] body .dataTable-table th a {
  text-align: right;
}
[view-mode=rtl] body .dataTable-bottom > div:last-child,
[view-mode=rtl] body .dataTable-top > div:last-child {
  float: left;
}
[view-mode=rtl] body .dataTable-bottom > div:first-child,
[view-mode=rtl] body .dataTable-top > div:first-child {
  float: right;
}
[view-mode=rtl] body .countdown1 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .countdown1 > div {
  margin-left: 0.5rem;
  margin-right: 0;
  direction: rtl;
}
[view-mode=rtl] body .countdown1 > div:last-child {
  margin-right: 0;
  margin-left: 0;
}
[view-mode=rtl] body .coming-soon-card .countdown3 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .coming-soon-card .countdown3 > div {
  margin-right: 0;
  margin-left: 0.25rem;
  direction: rtl;
}
[view-mode=rtl] body .coming-soon-card .countdown3 > div span.word {
  margin-left: 0;
  margin-right: 2px;
}
[view-mode=rtl] body .coming-soon-card .countdown3 > div:last-child {
  margin-right: 0;
  margin-left: 0;
}
[view-mode=rtl] body .countdown2 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .countdown2 > div {
  margin-left: 0.5rem;
  direction: rtl;
}
[view-mode=rtl] body .countdown2 > div:first-child {
  margin-left: 0.5rem;
}
[view-mode=rtl] body .countdown2 > div:last-child {
  margin-left: 0;
}
[view-mode=rtl] body .single-counter-wrap .solid-line.ms-0 {
  margin-left: auto !important;
  margin-right: 0;
}
[view-mode=rtl] body #password-visibility {
  right: auto;
  left: 0.625rem;
}
[view-mode=rtl] body #pswmeter {
  direction: rtl;
}
[view-mode=rtl] body .chat-user-list li .chat-options-btn .dropdown-menu a i {
  margin-left: 0.25rem;
  margin-right: 0;
}
[view-mode=rtl] body .chat-footer form {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .chat-footer form .dropup.me-2 {
  margin-left: 0.5rem;
  margin-right: 0 !important;
}
[view-mode=rtl] body .chat-footer form .form-control {
  direction: rtl;
}
[view-mode=rtl] body .video-call-screen .call-btn-group {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
[view-mode=rtl] body .gallery-img {
  direction: ltr;
}
[view-mode=rtl] body .language-lists .form-check {
  text-align: right;
  padding-left: 0;
}
[view-mode=rtl] body .cs-newsletter-form .btn-close {
  right: auto;
  left: 1rem;
}

/* :: Dark */
[data-theme=dark] {
  background-color: var(--dark);
}
[data-theme=dark] .dark-mode-switching {
  background-color: var(--dark-primary);
}
[data-theme=dark] .dark-mode-switching i {
  color: var(--warning-500);
}
[data-theme=dark] .dark-mode-switching .dark-mode-text {
  display: block;
}
[data-theme=dark] .dark-mode-switching .light-mode-text {
  display: none;
}
[data-theme=dark] .rtl-mode-switching {
  background-color: var(--dark-primary);
}
[data-theme=dark] .rtl-mode-switching i {
  color: var(--warning-500);
}
[data-theme=dark] body {
  background-color: var(--dark-primary);
}
[data-theme=dark] h1,
[data-theme=dark] h2,
[data-theme=dark] h3,
[data-theme=dark] h4,
[data-theme=dark] h5,
[data-theme=dark] h6,
[data-theme=dark] p,
[data-theme=dark] span, [data-theme=dark] li {
  color: var(--white);
}
[data-theme=dark] #preloader {
  background-color: var(--dark-primary);
}
[data-theme=dark] .card {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .border {
  border-color: rgba(255, 255, 255, 0.15) !important;
}
[data-theme=dark] .border-top {
  border-top-color: rgba(255, 255, 255, 0.15) !important;
}
[data-theme=dark] .border-start {
  border-left-color: rgba(255, 255, 255, 0.15) !important;
}
[data-theme=dark] .border-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.15) !important;
}
[data-theme=dark] .border-end {
  border-right-color: rgba(255, 255, 255, 0.15) !important;
}
[data-theme=dark] .border-content > span {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
[data-theme=dark] .border-primary {
  border-color: var(--primary-500) !important;
}
[data-theme=dark] .border-secondary {
  border-color: var(--secondary-500) !important;
}
[data-theme=dark] .border-success {
  border-color: var(--success-500) !important;
}
[data-theme=dark] .border-warning {
  border-color: var(--warning-500) !important;
}
[data-theme=dark] .border-danger {
  border-color: var(--danger-500) !important;
}
[data-theme=dark] .border-info {
  border-color: var(--info-500) !important;
}
[data-theme=dark] .border-light {
  border-color: var(--primary-200) !important;
}
[data-theme=dark] .border-dark {
  border-color: var(--dark) !important;
}
[data-theme=dark] .border-white {
  border-color: var(--white) !important;
}
[data-theme=dark] .bg-white {
  background-color: var(--dark-secondary) !important;
}
[data-theme=dark] .bg-gray {
  background-color: var(--primary-950) !important;
}
[data-theme=dark] .bg-light {
  background-color: var(--primary-950) !important;
}
[data-theme=dark] .img-thumbnail {
  border-color: rgba(255, 255, 255, 0.15);
  background-color: transparent;
}
[data-theme=dark] .dropdown-menu {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .dropdown-menu .dropdown-item {
  color: var(--white);
}
[data-theme=dark] .dropdown-menu .dropdown-item:hover,
[data-theme=dark] .dropdown-menu .dropdown-item:focus {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .demo-header-wrapper {
  background-color: var(--dark-primary);
  border-bottom-color: rgba(255, 255, 255, 0.07);
}
[data-theme=dark] .preview-iframe-wrapper .preview-hero-area {
  background-color: var(--dark-primary);
}
[data-theme=dark] .preview-iframe-wrapper .preview-hero-area .version-number {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .preview-iframe-wrapper .preview-hero-area .demo-title span {
  color: var(--warning-500);
}
[data-theme=dark] .preview-iframe-wrapper .qr-code-wrapper img {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .preview-iframe-wrapper .qr-code-wrapper h6 {
  color: var(--white);
}
[data-theme=dark] .preview-iframe-wrapper .features-area {
  background-color: var(--dark);
}
[data-theme=dark] .preview-iframe-wrapper .features-area .card {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: transparent;
}
[data-theme=dark] .preview-iframe-wrapper .features-area .card.active {
  border-color: transparent !important;
  background-color: var(--dark-secondary) !important;
}
[data-theme=dark] .preview-iframe-wrapper .features-area .card.active h6 {
  color: var(--white);
}
[data-theme=dark] .others-items-preview .alert {
  background-color: var(--dark) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .preview-hero-area .version-number {
  background-color: var(--primary-950);
  color: var(--white);
}
[data-theme=dark] .special-text {
  color: var(--warning-500);
}
[data-theme=dark] .affan-page-item {
  color: var(--white);
}
[data-theme=dark] .affan-page-item:hover, [data-theme=dark] .affan-page-item:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--warning-500);
  border-color: rgba(255, 255, 255, 0.15);
}
[data-theme=dark] .affan-page-item .icon-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-theme=dark] a.affan-element-item {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
}
[data-theme=dark] a.affan-element-item:hover, [data-theme=dark] a.affan-element-item:focus {
  color: var(--warning-500);
}
[data-theme=dark] .text-dark {
  color: var(--white) !important;
}
[data-theme=dark] .countdown1 > div span {
  color: var(--primary-500);
}
[data-theme=dark] .apexcharts-legend-text {
  color: var(--text) !important;
}
[data-theme=dark] .header-area {
  background-color: var(--dark-secondary);
  border-bottom-color: rgba(255, 255, 255, 0.07);
}
[data-theme=dark] .header-content .back-button a {
  color: var(--warning-500);
}
[data-theme=dark] .sidenav-wrapper,
[data-theme=dark] .offcanvas {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .sidenav-nav li a:hover,
[data-theme=dark] .sidenav-nav li a:focus,
[data-theme=dark] .sidenav-nav li .night-mode-nav:hover {
  color: var(--warning-500);
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .sidenav-nav li ul {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .nav-url .dropdown-icon i {
  color: var(--warning-500);
}
[data-theme=dark] .nav-url.dd-open {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .sidenav-nav li ul li a:hover,
[data-theme=dark] .sidenav-nav li ul li a:focus {
  background-color: transparent;
}
[data-theme=dark] .social-info-wrap {
  border-top-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .social-info-wrap a:hover, [data-theme=dark] .social-info-wrap a:focus {
  color: var(--warning-500);
}
[data-theme=dark] .copyright-info p a {
  color: var(--white);
}
[data-theme=dark] .header-content .setting-trigger-btn {
  color: var(--warning-500);
}
[data-theme=dark] .header-content .setting-trigger-btn span {
  background-color: var(--danger-500);
}
[data-theme=dark] .header-content.header-style-five .navbar--toggler {
  border-color: rgba(255, 255, 255, 0.15);
}
[data-theme=dark] .header-content .navbar--toggler span {
  background-color: var(--white);
}
[data-theme=dark] .header-demo-bg {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .header-content.header-style-two .search-trigger-btn {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
}
[data-theme=dark] .header-content.header-style-two .navbar--toggler {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .header-content.header-style-three .navbar--toggler {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .header-content.header-style-six .search-trigger-btn {
  color: var(--warning-500);
}
[data-theme=dark] .toast {
  background-color: var(--dark-secondary);
  border-color: transparent;
}
[data-theme=dark] .toast-header {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: transparent;
}
[data-theme=dark] .custom-alert-one {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .custom-toast-1 .toast-body .toast-text p {
  color: var(--white);
}
[data-theme=dark] .custom-alert-one.alert-dark {
  color: var(--white);
}
[data-theme=dark] .custom-alert-one.alert-dark::after {
  background-color: var(--white);
}
[data-theme=dark] .form-control::-webkit-input-placeholder {
  color: var(--white);
  opacity: 1;
}
[data-theme=dark] .form-control::-moz-placeholder {
  color: var(--white);
  opacity: 1;
}
[data-theme=dark] .form-control:-ms-input-placeholder {
  color: var(--white);
  opacity: 1;
}
[data-theme=dark] .form-control::-ms-input-placeholder {
  color: var(--white);
  opacity: 1;
}
[data-theme=dark] .form-control::placeholder {
  color: var(--white);
  opacity: 1;
}
[data-theme=dark] .form-control,
[data-theme=dark] .form-select {
  color: var(--white);
  background-color: var(--dark-secondary);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-theme=dark] .form-control.form-control-clicked,
[data-theme=dark] .form-select.form-control-clicked {
  color: var(--white);
  background-color: var(--primary-950);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-theme=dark] #password-visibility i {
  color: var(--warning-700);
}
[data-theme=dark] .form-control-plaintext {
  color: var(--white);
}
[data-theme=dark] .was-validated .form-control:invalid,
[data-theme=dark] .form-control.is-invalid {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .input-group-text {
  color: var(--white);
  background-color: var(--dark-secondary);
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .form-check-label {
  color: var(--white);
}
[data-theme=dark] .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23686868' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
[data-theme=dark] .form-select[multiple],
[data-theme=dark] .form-select[size]:not([size="1"]) {
  background-image: none;
}
[data-theme=dark] .form-control::-webkit-file-upload-button {
  color: var(--white);
  background-color: var(--dark);
  border-inline-end-width: 0;
}
[data-theme=dark] .form-control::file-selector-button {
  color: var(--white);
  background-color: var(--dark);
  border-inline-end-width: 0;
}
[data-theme=dark] .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--primary-950);
}
[data-theme=dark] .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--primary-950);
}
[data-theme=dark] #rangevalue {
  color: var(--white) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .form-range::-moz-range-track {
  background-color: var(--dark);
}
[data-theme=dark] .irs--flat .irs-line {
  background-color: var(--dark);
}
[data-theme=dark] .irs--flat .irs-min, [data-theme=dark] .irs--flat .irs-max {
  color: var(--white);
  background-color: var(--dark);
}
[data-theme=dark] .autocomplete-items > div {
  background-color: transparent;
  color: var(--white);
}
[data-theme=dark] .autocomplete-items > div strong {
  color: var(--warning-500);
}
[data-theme=dark] .element-heading-wrapper i {
  background-color: var(--dark-secondary);
  color: var(--warning-500);
}
[data-theme=dark] .toast-body,
[data-theme=dark] .card-body {
  color: var(--white);
}
[data-theme=dark] .login-wrapper {
  background-color: var(--dark-primary);
}
[data-theme=dark] .login-meta-data a,
[data-theme=dark] .login-back-button a {
  color: var(--warning-500);
}
[data-theme=dark] .register-form .form-check-label {
  color: rgba(255, 255, 255, 0.7);
}
[data-theme=dark] .otp-sec {
  color: var(--warning-500);
}
[data-theme=dark] .accordion-style-five .accordion-item h6 {
  color: var(--white);
}
[data-theme=dark] .btn-link {
  color: var(--primary-500);
}
[data-theme=dark] .btn-link:hover, [data-theme=dark] .btn-link:focus {
  color: var(--warning-500);
}
[data-theme=dark] .footer-nav-area {
  background-color: var(--dark-secondary);
  border-top-color: rgba(255, 255, 255, 0.07);
}
[data-theme=dark] .footer-nav {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .footer-nav ul li a {
  color: var(--white);
}
[data-theme=dark] .footer-nav ul li a span {
  color: rgba(255, 255, 255, 0.5);
}
[data-theme=dark] .footer-nav ul li a:hover,
[data-theme=dark] .footer-nav ul li a:focus,
[data-theme=dark] .footer-nav ul li.active a {
  color: var(--white);
}
[data-theme=dark] .footer-nav ul li a:hover span,
[data-theme=dark] .footer-nav ul li a:focus span,
[data-theme=dark] .footer-nav ul li.active a span {
  color: var(--warning-500);
}
[data-theme=dark] .footer-nav.footer-style-three ul li a {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .footer-nav.footer-style-three ul li.active a,
[data-theme=dark] .footer-nav.footer-style-three ul li a:hover,
[data-theme=dark] .footer-nav.footer-style-three ul li a:focus {
  background-color: var(--warning-500);
  color: var(--white);
}
[data-theme=dark] .footer-nav.footer-style-five ul li::after,
[data-theme=dark] .footer-nav.footer-style-six ul li::after {
  background-color: var(--warning-500);
}
[data-theme=dark] .setting-popup-card .btn-close,
[data-theme=dark] .custom-alert-one .btn-close,
[data-theme=dark] .toast .toast-header .btn-close,
[data-theme=dark] .custom-toast-1 .btn-close {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}
[data-theme=dark] .footer-nav.footer-style-two li.active a {
  background-color: rgba(241, 177, 15, 0.3);
}
[data-theme=dark] .footer-nav.footer-style-two li.active a::before {
  background-color: var(--warning-500);
}
[data-theme=dark] .accordion-item {
  background-color: var(--dark-secondary);
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .accordion-button {
  background-color: var(--dark-primary);
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .accordion-button:not(.collapsed) {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .accordion-collapse {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .accordion-item .accordion-body {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .accordion-style-one .accordion-item h6 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--warning-500);
}
[data-theme=dark] .accordion-style-one .accordion-item h6.collapsed {
  color: var(--white);
}
[data-theme=dark] .accordion-style-two .accordion-item h6.collapsed {
  color: var(--white);
}
[data-theme=dark] .accordion-style-two .accordion-item h6 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .accordion-style-three .accordion-item h6 {
  background-color: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: var(--white);
}
[data-theme=dark] .accordion-style-four .accordion-item {
  border: 0;
}
[data-theme=dark] .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-theme=dark] .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f1b10f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-theme=dark] .breadcrumb-wrapper {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .breadcrumb {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .breadcrumb .breadcrumb-item {
  color: var(--white);
}
[data-theme=dark] .breadcrumb .breadcrumb-item a {
  color: var(--white);
}
[data-theme=dark] .breadcrumb .breadcrumb-item a:hover, [data-theme=dark] .breadcrumb .breadcrumb-item a:focus {
  color: var(--warning-500);
}
[data-theme=dark] .breadcrumb-three .breadcrumb-item a i {
  color: var(--warning-500);
}
[data-theme=dark] .timeline-card {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .timeline-card::after {
  background-color: var(--white);
}
[data-theme=dark] .timeline-card.bg-dark {
  border-left-color: var(--primary-950) !important;
}
[data-theme=dark] .rating-card-one > div {
  background-color: transparent;
}
[data-theme=dark] .rating-card-two > div {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .rating-detail .progress-bar-wrapper {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .list-group-item {
  color: var(--white);
  background-color: var(--dark-secondary);
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .list-group-item.active {
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: var(--warning-500);
}
[data-theme=dark] .list-group-item.disabled {
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: rgba(241, 177, 15, 0.4);
}
[data-theme=dark] .page-link {
  color: var(--white);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .page-item.active .page-link {
  color: var(--white);
  background-color: var(--danger-500);
  border-color: var(--danger-500);
}
[data-theme=dark] .page-item.disabled .page-link {
  color: rgba(241, 177, 15, 0.4);
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .pagination.pagination-one .page-item:first-child .page-link {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .pagination.pagination-one .page-link {
  border-color: rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .pagination.pagination-one .page-item:last-child .page-link {
  border-color: rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .pagination.pagination-one .page-item.active .page-link::after {
  background-color: var(--danger-500);
}
[data-theme=dark] .progress {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .skill-progress-bar .skill-icon {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .skill-progress-bar .skill-icon svg {
  color: var(--white) !important;
}
[data-theme=dark] .skill-progress-bar .skill-name p {
  color: var(--white);
}
[data-theme=dark] .chat-user-status-slides .user-status-slide a {
  background-color: var(--dark-secondary);
  -webkit-box-shadow: none;
          box-shadow: none;
}
[data-theme=dark] .chat-user-list {
  -webkit-box-shadow: none;
          box-shadow: none;
}
[data-theme=dark] .chat-user-list li {
  background-color: var(--dark-secondary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .chat-user-list li.chat-unread .chat-user-info .last-chat p {
  color: var(--warning-500);
}
[data-theme=dark] .chat-user-list li .chat-options-btn li,
[data-theme=dark] .chat-user-list li .chat-options-btn li:hover,
[data-theme=dark] .chat-user-list li .chat-options-btn li:focus {
  background-color: transparent;
}
[data-theme=dark] .chat-user-list li .chat-options-btn .dropdown-menu a:hover,
[data-theme=dark] .chat-user-list li .chat-options-btn .dropdown-menu a:focus {
  color: var(--warning-500);
  background-color: transparent;
}
[data-theme=dark] .chat-user-list li:last-child {
  border-bottom: 0;
}
[data-theme=dark] .chat-user-info .user-thumbnail-name p {
  color: var(--white);
}
[data-theme=dark] .call-video-wrapper a {
  color: var(--white);
}
[data-theme=dark] .single-chat-item .user-message .single-message p {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .single-chat-item.outgoing .user-message .single-message p {
  background-color: var(--primary-950);
  color: var(--white);
}
[data-theme=dark] .single-chat-item .user-message .download-file-wrap {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .single-chat-item .user-message .single-message .typing {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .chat-footer {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .chat-footer .btn-emoji {
  color: var(--warning-500);
}
[data-theme=dark] .chat-footer .btn-add-file {
  color: var(--warning-500);
}
[data-theme=dark] .single-chat-item .user-message .dropdown-menu a:hover,
[data-theme=dark] .single-chat-item .user-message .dropdown-menu a:focus {
  color: var(--warning-500);
}
[data-theme=dark] .chat-user--info .user-thumbnail-name p {
  color: var(--white);
}
[data-theme=dark] .dataTable-top .dataTable-selector,
[data-theme=dark] .dataTable-top .dataTable-input {
  border-color: rgba(255, 255, 255, 0.1);
  color: var(--warning-500);
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .dataTable-container .dataTable-table thead > tr > th {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .dataTable-container .dataTable-table tbody tr td,
[data-theme=dark] .dataTable-container .dataTable-table tbody tr th {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .dataTable-sorter::before {
  border-top-color: var(--warning-500);
}
[data-theme=dark] .dataTable-sorter::after {
  border-bottom-color: var(--warning-500);
}
[data-theme=dark] .dataTable-pagination li a {
  color: var(--white);
}
[data-theme=dark] .table {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .table-hover > tbody > tr:hover {
  color: var(--text);
}
[data-theme=dark] .table-striped > tbody > tr:nth-of-type(2n+1) {
  color: rgba(255, 255, 255, 0.7);
}
[data-theme=dark] .table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}
[data-theme=dark] .table-light {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.15);
}
[data-theme=dark] .table-light tr th,
[data-theme=dark] .table-light tr td {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.15);
}
[data-theme=dark] .cart-table .border-top {
  border-top-color: rgba(255, 255, 255, 0.05) !important;
}
[data-theme=dark] .cart-table .qty-text {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: transparent;
  color: var(--white);
}
[data-theme=dark] .cart-table table .remove-product {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .cart-table table .remove-product:hover,
[data-theme=dark] .cart-table table .remove-product:focus {
  color: var(--white);
  background-color: var(--danger-500);
}
[data-theme=dark] .table > :not(caption) > * > * {
  color: var(--white);
  background-color: var(--dark-secondary);
}
[data-theme=dark] tbody, [data-theme=dark] td, [data-theme=dark] tfoot, [data-theme=dark] th, [data-theme=dark] thead, [data-theme=dark] tr {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .dataTable-pagination li a {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .dataTable-pagination li.active a {
  background-color: var(--primary-500);
}
[data-theme=dark] .shop-pagination small {
  border-left-color: var(--warning-500);
}
[data-theme=dark] .notification-message {
  background-color: var(--dark) -primary !important;
}
[data-theme=dark] .offcanvas-start .btn-close,
[data-theme=dark] .offcanvas-top .btn-close,
[data-theme=dark] .offcanvas-end .btn-close,
[data-theme=dark] .offcanvas-bottom .btn-close {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}
[data-theme=dark] .scrollspy-indicatiors .nav-link {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
}
[data-theme=dark] .scrollspy-indicatiors .nav-link.active {
  background-color: var(--warning-500);
  color: var(--white);
}
[data-theme=dark] .price-table-two .single-price-table {
  background-color: rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .price-table-two .single-price-table.active {
  background-color: var(--primary-950);
}
[data-theme=dark] .countdown1 > div .num {
  color: var(--warning-500);
}
[data-theme=dark] .countdown1 {
  color: var(--white);
}
[data-theme=dark] .single-product-card .product-title {
  color: var(--white);
}
[data-theme=dark] .single-product-card .sale-price {
  color: var(--warning-500);
}
[data-theme=dark] .team-member-card {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .language-lists li {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .notification-area .alert {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.05);
  color: var(--white);
}
[data-theme=dark] .notification-area .alert.unread {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
}
[data-theme=dark] .single-search-result {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .single-search-result a {
  color: rgba(241, 177, 15, 0.75);
}
[data-theme=dark] .blog-title {
  color: var(--white) !important;
}
[data-theme=dark] .blog-title:hover,
[data-theme=dark] .blog-title:focus {
  color: var(--warning-500) !important;
}
[data-theme=dark] .single-setting-panel a:hover, [data-theme=dark] .single-setting-panel a:focus {
  color: var(--warning-500);
}
[data-theme=dark] .standard-tab .nav {
  border-color: rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .standard-tab .btn {
  color: var(--white);
}
[data-theme=dark] .minimal-tab .btn {
  color: var(--white);
}
[data-theme=dark] .minimal-tab .btn.active {
  border-bottom-color: var(--warning-500);
  color: var(--warning-500);
}
[data-theme=dark] .nav-tabs {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .nav-tabs .nav-link {
  color: var(--white);
}
[data-theme=dark] .nav-tabs .nav-link:hover, [data-theme=dark] .nav-tabs .nav-link.active {
  border-color: rgba(255, 255, 255, 0.05);
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .tab-content {
  border-color: rgba(255, 255, 255, 0.05);
}
[data-theme=dark] .price-table-one .nav-tabs .nav-link,
[data-theme=dark] .price-table-one .nav-tabs .nav-link:hover {
  border-color: rgba(255, 255, 255, 0.05);
  background-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .price-table-one .nav-tabs .nav-link.active {
  background-color: var(--primary-950);
  color: var(--white);
  border-color: var(--primary-950);
}
[data-theme=dark] .offline-online-card h6 {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}
[data-theme=dark] .offline-online-card strong {
  color: var(--warning-500) !important;
}
[data-theme=dark] .modal-content {
  background-color: var(--dark-secondary);
}
[data-theme=dark] .modal-content .btn-close {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}
[data-theme=dark] .modal-content .modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .modal-content .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .single-plan-check {
  border-color: rgba(255, 255, 255, 0.1);
}