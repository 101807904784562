@media print {
    body,
    html {
        background-color: #ffffff !important; /* Force white background during printing */
    }

    @page {
        size: A4; /* Set the size to A4 */
        margin: 10mm; /* Adjust the margins as needed */
    }

    main {
        padding-top: 200px !important;
        padding-left: 50px;
    }

    .table {
        border: 1px solid #000000;
        border-collapse: collapse;
    }

    tr,
    td,
    th {
        border: 1px solid #000000; /* Sets a border for table cells and headers */
        padding: 8px; /* Optional: adds padding inside the cells */
    }

    td {
        font-size: 16px;
    }

    .footer {
        padding-bottom: 40px;
    }

    .sign {
        text-align: end;
        padding-top: 15px;
    }

    .powered {
        font-size: 16px;
        text-align: center;
    }
}
