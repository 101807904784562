@media print {
  body,
  html {
    background-color: #ffffff !important; /* Force white background during printing */
  }

  /* main {
    padding: 50px !important;
  } */

  @page {
    size: A4; /* Set the size to A4 */
    margin: 10mm; /* Adjust the margins as needed */
  }
  
  .table {
    border: 1px solid #000000;
    border-collapse: collapse;
  }
  
  tr,td, th {
    border: 1px solid #000000; /* Sets a border for table cells and headers */
    padding: 8px; /* Optional: adds padding inside the cells */
  }

  td {
    font-size: 16px; 
  }

  .footer {
    padding-bottom: 40px;
  }

  .sign {
    text-align: end;
    padding-top: 50px;
  }

  .powered {
    font-size: 16px;
    text-align: center;
  }

  .hospital-name {
    font-size: 25px;
    color: #2980b9; /* Black text for print */
    text-transform: uppercase;
    font-weight: bold !important;
  }

  .other-line {
    font-size: 15px;
    color: #27ae60; /* Black text for print */
  }
}
